// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { typographyClasses, colors } from 'frontend-assets'
import CheckBox from '../CheckBox/CheckBox'
import TextButton from '../TextButton/TextButton'
import { postUsersSelfServiceMessagesSignedRequest } from '../../../utils/generated-api-requests/users'
import { formattedDate } from '../../../utils/commonUtils'
import { closeModal } from '../../../actions/modals'
import { MAINTENANCE_OUTAGE_MODAL } from '../../../constants/modalConstants'

const {
  h4, bodyBig, bodyBigBold, bodyDefault, bodyDefaultBold
} = typographyClasses
const { primary100, dark80 } = colors

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  brandLogo: {
    paddingTop: '48px',
    paddingBottom: '32px',
    width: '160px',
    margin: '0 auto'
  },
  content: {
    maxWidth: '550px',
    overflow: 'auto',
    margin: '0 auto'
  },
  checkboxRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8
  },
  buttonText: {
    ...bodyBig,
    color: dark80,
    marginLeft: '5px'
  },
  infoText: {
    ...bodyDefault,
    color: dark80
  },
  systemUpdatedText: {
    ...bodyBigBold,
    color: dark80,
    paddingBottom: '15px'
  },
  scheduleTitle: {
    ...h4,
    color: primary100,
    margin: '0 auto 40px',
    textAlign: 'center'
  },
  messageContainer: {
    marginBottom: '56px'
  },
  messageTextMarkdown: {
    ...bodyDefault,
    '& h3': {
      ...bodyDefaultBold
    },
    '& a': {
      color: primary100
    }
  },
  footerButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px 0 40px',
    '& .MuiButton-root ': {
      width: '50%'
    },
  }
}))

type Props = {|
  maintenanceBreaks: TVDServiceMessage
|}

const MaintenanceOutageModal = ({ maintenanceBreaks }: Props): React$Element<'div'> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  const maintenanceType = (): 'before' | 'during' | 'after' => {
    if (!maintenanceBreaks.endDate) return 'before'

    const startTime = Date.parse(maintenanceBreaks.startDate)
    const endTime = Date.parse(maintenanceBreaks.endDate)
    const now = new Date().getTime()
    if (now > endTime) return 'after'
    else if (now >= startTime && now <= endTime) return 'during'
    return 'before'
  }

  const continueClick = () => {
    if (doNotShowAgain || maintenanceType() === 'after') {
      postUsersSelfServiceMessagesSignedRequest(
        {
          body: {
            serviceMessageId: maintenanceBreaks.id,
            messageHash: maintenanceBreaks.messageHash,
          },
        },
        {},
        () => {
          dispatch(closeModal(MAINTENANCE_OUTAGE_MODAL))
        },
        null,
        {}
      )
    }

    dispatch(closeModal(MAINTENANCE_OUTAGE_MODAL))
  }

  return (
    <div className={classes.modal}>
      <img
        data-testid='test-logo'
        className={classes.brandLogo}
        src='/assets/images/realaizer/Realaizer_logo.svg'
        alt='Realaizer logo' />
      <div className={classes.content}>
        <div className={classes.infoText}>
          {maintenanceType() === 'during' && (
            <>
              <div className={classes.messageContainer}>
                <ReactMarkdown className={classes.messageTextMarkdown}>
                  {maintenanceBreaks.messageText}
                </ReactMarkdown>
              </div>
              <div className={classes.checkboxRow}>
                <CheckBox
                  value={doNotShowAgain}
                  onChange={() => {
                    setDoNotShowAgain(!doNotShowAgain)
                  }} />
                <Typography className={classes.buttonText}>
                  {t('maintenanceOutageNotification._DONT_SHOW_')}
                </Typography>
              </div>
            </>
          )}
          {maintenanceType() === 'after' && (
            <>
              <Typography className={classes.scheduleTitle}>
                {t('maintenanceOutageNotification._SYSTEM_UPDATED_TITLE_')}
                {formattedDate(maintenanceBreaks.endDate)}
              </Typography>
              <Typography className={classes.systemUpdatedText}>
                {t('maintenanceOutageNotification._SYSTEM_UPDATED_')}
              </Typography>
              <div className={classes.messageContainer}>
                <ReactMarkdown className={classes.messageTextMarkdown}>
                  {maintenanceBreaks.messageText}
                </ReactMarkdown>
              </div>
            </>
          )}
          <div className={classes.footerButton}>
            <TextButton
              variant='login'
              text={t('buttons._CONTINUE_')}
              onClick={continueClick} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaintenanceOutageModal
