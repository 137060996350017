// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

import { closeAllListItems } from '../../../../actions/list'
import { PRICEITEM, CREATE_FROM_PRICING, ITEM, HEADING, ASSEMBLY } from '../../../../constants/contentTypes'
import { QUANTITY, DESCRIPTION } from '../../../../constants/attributes'
import { buildOpenPricingAttributeEdit } from '../../../../actions/modals'
import HierarchicalListContainer from '../../../containers/HierarchicalListContainer/HierarchicalListContainer'
import {
  getPricelistWithEstimateIdRequest,
  getPricelistColumnsWithEstimateIdRequest,
  postPriceitemWithIdRequest,
  getPricelistItemItemsWithIdRequest
} from '../../../../utils/generated-api-requests/buildingelements'
import TextButton from '../../../common/TextButton/TextButton'
import { MODAL_TYPE_PRICING_ATTRIBUTE_EDIT } from '../../../../constants/modalConstants'
import { postPolling } from '../../../../actions/postPolling'
import ListSearchField from '../../../common/lists/ListSearchField/ListSearchField'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'auto'
  },
  searchFieldWrapper: {
    margin: '10px 30px'
  },
}

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type MappedProps = {|
  listFilter: string, // the set filter from Store to a list
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>
|}

type DispatchProps = {|
  dispatchBuildOpenPricingAttributeEdit: (Object) => void, // opens attribute edit for pricing row
  dispatchPostPolling: () => void, // start post polling
  dispatchCloseAllListItems: () => void, // closes all list items of a given list
|}

type ReceivedProps = {|
  resourceId: string, // id of the row that opened CreateFromPricing
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

// Only one this type of list can be in the app
const listId = CREATE_FROM_PRICING

export class CreateFromPricing extends Component<Props> {
  searchField = (): React$Element<any> => {
    const { classes, dispatchCloseAllListItems } = this.props

    return (
      <div className={classes.searchFieldWrapper}>
        <ListSearchField
          disableOnBlur
          listId={listId}
          onSearch={(searchString: string) => {
            const emptySearch = searchString === ''
            getPricelistWithEstimateIdRequest(
              {
                query: {
                  listType: 'lazyload',
                  searchString: !emptySearch ? searchString : undefined
                }
              },
              { listStoreId: listId, mergeOptions: { initialListItems: {}, useExpanded: true } },
              () => {
                if (emptySearch) {
                  dispatchCloseAllListItems()
                }
              }
            )
          }}
          filteredTypes={[PRICEITEM.toLowerCase(), ASSEMBLY.toLowerCase()]} />
      </div>
    )
  }

  getAddButton = (row: Object): React$Element<any> | null => {
    const { isEstimateLockedToCurrentUser, isEstimateFrozen } = this.props
    if (!isEstimateLockedToCurrentUser || isEstimateFrozen) return null
    const withoutButtonRowTypes = [ITEM.toLowerCase(), HEADING.toLowerCase()]
    if (withoutButtonRowTypes.includes(row.type)) {
      return null
    }

    const { t, dispatchBuildOpenPricingAttributeEdit, dispatchPostPolling } = this.props

    const {
      columnData: { Quantity, Description },
      type,
      parentId,
      id,
    } = row

    const params = {
      data: { Quantity },
      parentId,
      pricelistItemId: id,
      type
    }

    const modifiedColumnData = row.modifiedColumnData || {}

    const onAddButtonClick = () => {
      if (type === PRICEITEM.toLowerCase()) {
        postPriceitemWithIdRequest({
          path: { id: parentId },
          query: { pricelistItemId: id },
          body: params.data
        }, {}, () => { dispatchPostPolling() })
      } else {
        dispatchBuildOpenPricingAttributeEdit({
          type,
          parentId,
          modifiedColumnData,
          testId: row.Description,
          pricelistItemId: id,
          description: Description,
          id: MODAL_TYPE_PRICING_ATTRIBUTE_EDIT
        })
      }
    }
    return (
      <TextButton
        id={`${CREATE_FROM_PRICING}-add-button`}
        variant='small'
        text={t('buttons._ADD_')}
        onClick={onAddButtonClick} />
    )
  }

  onRowClick = (row: TVDListItem) => {
    const { canHaveChildren, isOpen, id } = row
    if (canHaveChildren && !isOpen) {
      getPricelistItemItemsWithIdRequest({ path: { id } }, { listStoreId: listId })
    }
  }

  render(): React$Element<any> {
    const {
      classes,
      resourceId,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser

    } = this.props

    const openedFromList = resourceId !== CREATE_FROM_PRICING

    const didMountCb = () => {
      const payload = { listStoreId: listId, mergeOptions: { useExpanded: true } }
      getPricelistColumnsWithEstimateIdRequest(payload)
      getPricelistWithEstimateIdRequest(
        {
          query: {
            searchString: openedFromList ? resourceId : undefined,
            listType: 'lazyload'
          }
        },
        payload,
      )
    }

    return (
      <div className={classes.root}>
        {this.searchField()}
        <HierarchicalListContainer
          listType={CREATE_FROM_PRICING}
          initialColumnWidths={{
            [DESCRIPTION]: 560,
          }}
          isEstimateFrozen={isEstimateFrozen}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          listId={CREATE_FROM_PRICING}
          testId='PricingList'
          editableColumns={[QUANTITY]}
          addedColumns={[{ propertyName: 'addButtonColumn', localizedName: '', dataType: '' }]}
          columnsVisibleOnHover={['addButtonColumn']}
          didMountCallback={didMountCb}
          onRowClick={this.onRowClick}
          wrappedCellContents={{
            [DESCRIPTION]: ({ content }: Object) => <DescriptionCell testId='listRowCell-Description-text' text={content} />,
            addButtonColumn: ({ row }: Object) => this.getAddButton(row)
          }} />
      </div>
    )
  }
}

type ReduxState = {
  +widgets: Object,
  +list: Object,
  +view: Object,
  +app: Object
}

const mapStateToProps = ({
  list,
  app: {
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  }
}: ReduxState): MappedProps => {
  const { listFilter } = list[listId] || {}

  return {
    listFilter,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  }
}

type ownProps = {
  t: Function, // i18n translate function
  resourceId: string,
}

function mapDispatchToProps(dispatch: Function, props: ownProps): DispatchProps {
  const { t } = props

  return {
    dispatchCloseAllListItems: () => { dispatch(closeAllListItems(CREATE_FROM_PRICING)) },
    dispatchPostPolling: () => { dispatch(postPolling()) },
    dispatchBuildOpenPricingAttributeEdit: (contentProps: Object) => {
      dispatch(buildOpenPricingAttributeEdit(contentProps, t('widgets._ATTRIBUTES_').toUpperCase()))
    },
  }
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CreateFromPricing)
