// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles, Table } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import ListRow from '../common/ListRow/ListRow'
import ListRowCell from '../common/ListRowCell/ListRowCell'
import ListHeaderCell from '../common/ListHeaderCell/ListHeaderCell'
import { combineStyleClassNames } from '../../../../utils/styleUtils'


const commonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  height: 32,
  fontSize: 10,
  fontWeight: 400,
}

const styles = ({ palette }: Object): Object => ({
  listHeaderCellContent: {
    ...commonWrapperStyles,
    justifyContent: 'flex-end',
    paddingRight: '4px',
    color: palette.dark100,
  },
  firstListHeaderCellContent: {
    ...commonWrapperStyles,
    justifyContent: 'flex-start',
    letterSpacing: 0.5,
    color: palette.dark100,
    paddingLeft: 32,
  },
  descriptionCell: {
    marginRight: 10,
  },
  rowCellContent: {
    textAlign: 'right',
    color: palette.nevada,
  },
  tableRoot: {
    width: 'auto',
  }
})

export type TVDSimpleDataTableColumn = {|
  key: string, // unique key indentified for the column
  localizedName: string, // localized name of the column
|}

type Props = {|
  classes: Object, // withStyles classes object
  columns: Array<TVDSimpleDataTableColumn>, // column contents in an array for the table
  // rows with their cell content. Each item in the array is a row, and each item inside the row is the corresponding cell content.
  // E.g columns = ['user'] rows = [['Matti']] will lead to a table with one column "User" and one row with the user infromation being "Matti"
  rows: Array<Array<string>>,
  wrappedCellContents?: Object, // object containing functions that will let you wrap cell's content customly from above. e.g index0: (content) => <span>{content}</span>
  headerCellInitialWidth?: number, // initial width given to header cell's resize wrapper
  pusherWidth?: number, // width of the last header cell that pushes the content to left
  testId?: string | null, // test id put to the <Table>
  fullWidthPusher?: boolean, // sets pusher to width of 100% if set to true and ignores pusherWidth
  cellClassNames?: {[columnKey: string]: string}, // optional cell class names assigned to both header and non header cells
  listRowClassName?: string, // optional class name for the list row component
|}

export class SimpleDataTable extends Component<Props> {
  static defaultProps = {
    wrappedCellContents: {},
    headerCellInitialWidth: 100,
    pusherWidth: 200,
    testId: null,
    fullWidthPusher: false,
    cellClassNames: {},
    listRowClassName: ''
  }

  get tableHead(): React$Element<typeof TableHead> {
    const {
      columns,
      classes,
      headerCellInitialWidth = 0,
      pusherWidth = 0,
      fullWidthPusher,
      cellClassNames
    } = this.props
    return (
      <TableHead>
        <ListRow noHoverEffect>
          {
            columns.map((column: TVDSimpleDataTableColumn, index: number) => (
              <ListHeaderCell
                initialWidth={this.getCellWidth(column)}
                key={`${column.key}-${headerCellInitialWidth}`}>
                <div
                  className={combineStyleClassNames(
                    index === 0 ? classes.firstListHeaderCellContent : classes.listHeaderCellContent,
                    cellClassNames?.[column.key]
                  )}>
                  {column.localizedName}
                </div>
              </ListHeaderCell>))
          }
          <ListHeaderCell resizeDisabled fullWidth={fullWidthPusher} initialWidth={pusherWidth} key={`pusher-header-cell-${pusherWidth}`} />
        </ListRow>
      </TableHead>
    )
  }

  get tableBody(): React$Element<typeof TableBody> {
    const {
      rows,
      wrappedCellContents,
      classes,
      cellClassNames,
      columns,
      listRowClassName
    } = this.props
    // disabling linter for no-index-key rule due to the component not having any other valid option as key
    /* eslint-disable react/no-array-index-key */

    return (
      <TableBody>
        {rows.map((rowCells: Array<any>, rowIndex: number) => (
          <ListRow
            className={listRowClassName}
            testId='SimpleDataTable-ListRow'
            key={`ListRow-${rowIndex}`}>
            {
              rowCells.map((cellContent: string | number | Object, rowCellIndex: number): null | React$Element<typeof ListRowCell> => (
                <ListRowCell
                  isFirstColumn={rowCellIndex === 0}
                  id={`SimpleDataTable-${rowCellIndex}`}
                  key={`ListRowCell-${rowCellIndex}`}>
                  {
                      wrappedCellContents && wrappedCellContents[`index${rowCellIndex}`] ?
                       wrappedCellContents[`index${rowCellIndex}`]({ content: cellContent, rowIndex }) :
                       <div className={combineStyleClassNames(classes.rowCellContent, cellClassNames?.[columns[rowCellIndex].key])}>
                         {cellContent}
                       </div>
                    }
                </ListRowCell>
                ))
            }
            <ListRowCell key='pusher-row-cell' />
          </ListRow>
        ))}
      </TableBody>
    )
    /* eslint-enable react/no-array-index-key */
  }

  getCellWidth(column: Object): number {
    const { headerCellInitialWidth = 0 } = this.props
    switch (column.key) {
      case 'description': return 280
      case 'createdBy': return 250
      default: return headerCellInitialWidth
    }
  }

  render(): React$Element<typeof Table> {
    const { classes } = this.props
    return (
      <Table data-testid={this.props.testId} classes={{ root: classes.tableRoot }} size='small'>
        {this.tableHead}
        {this.tableBody}
      </Table>
    )
  }
}

export default withStyles(styles)(SimpleDataTable)
