// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { unmountComponentAtNode } from 'react-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SpaceEquipmentProductAssemblyMFEContainer, {
  spaceEquipmentProductAssemblyMFERootId
} from '../SpaceEquipmentProductAssemblyMFEContainer/SpaceEquipmentProductAssemblyMFEContainer'
import SpaceSurfaceProductAssemblyMFEContainer, {
  spaceSurfaceProductAssemblyMFERootId
} from '../SpaceSurfaceProductAssemblyMFEContainer/SpaceSurfaceProductAssemblyMFEContainer'

type MappedProps = {|
  MFEStates: $PropertyType<TVDReduxStore, 'MFEStates'>
|}

type Props = {|
  ...MappedProps
|}

const MFEContainerComponents = {
  SpaceEquipmentProductAssemblyMFEContainer: {
    component: SpaceEquipmentProductAssemblyMFEContainer,
    rootId: spaceEquipmentProductAssemblyMFERootId
  },
  SpaceSurfaceProductAssemblyMFEContainer: {
    component: SpaceSurfaceProductAssemblyMFEContainer,
    rootId: spaceSurfaceProductAssemblyMFERootId
  }
}

class FloatingWindowContainer extends Component<Props> {
  render(): React$Element<any> {
    const { MFEStates } = this.props
    return (
      <>
        { MFEStates !== null &&
          Object.keys(MFEStates).map((MFEKey: string) => {
            const {
              componentName,
              isOpen,
              contentProps
            } = MFEStates[MFEKey]
            const MFEContainerComponent = MFEContainerComponents[componentName].component
            if (MFEContainerComponent && isOpen) {
              return <MFEContainerComponent key={`${MFEKey}${componentName}`} contentProps={contentProps} />
            } else if (MFEContainerComponent && !isOpen) {
              const element = document.getElementById(MFEContainerComponents[componentName].rootId)
              if (element) {
                unmountComponentAtNode(element)
              }
              return null
            }
            return null
          })
        }
      </>
    )
  }
}

const mapStateToProps = ({ MFEStates }: TVDReduxStore): MappedProps => ({
  MFEStates
})

export default compose(connect(mapStateToProps))(FloatingWindowContainer)

