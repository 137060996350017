// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { withStyles } from '@material-ui/core/styles'

import TextButton from '../../../common/TextButton/TextButton'
import OverflowTooltip from '../../../common/OverflowTooltip/OverflowTooltip'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import ListSearchField from '../../../common/lists/ListSearchField/ListSearchField'

import {
  SHARE,
  QUANTITYPCS,
  QUANTITY,
  UNIT_PRICE,
  DESCRIPTION
} from '../../../../constants/attributes'
import { ASSEMBLY, EQUIPMENT, PRICEITEM, SURFACES } from '../../../../constants/contentTypes'
import { addListItem, setListItemFilter } from '../../../../actions/list'
import {
  getSpacesSurfacesRegistryColumnsWithSpaceIdRequest,
  getSpacesSurfacesRegistryItemsWithItemIdRequest,
  getSpacesEquipmentRegistryColumnsWithSpaceIdRequest,
  getSpacesEquipmentRegistryItemsWithItemIdRequest,
} from '../../../../utils/generated-api-requests/spaces'

const styles = ({ palette }: Object): Object => ({
  searchFieldWrapper: {
    margin: '10px 30px'
  },
  amountCell: {
    display: 'flex'
  },
  unit: {
    marginLeft: '13px',
    color: palette.defaultText
  }
})

type HOCProps = {|
  t: Function, // i18next translation function
  classes: Object, // withStyles classes object
|}

type DispatchProps = {|
  dispatchAddListItem: (Object) => void, // fn to add list item to Store
  dispatchSetListItemFilter: (string) => void // fn to add search filter to the list to Store
|}

type ReceivedProps = {|
  itemId: string, // uuid of the surface item
  pricingListId: string, // id of pricing list
  pricingType: string, // type of pricing eq. equipment or surfaces
  spaceId: string, // uuid of the space id where the item is selected from
  type: string,
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...ReceivedProps,
|}

type State = {
  itemId: string | null,
}

export class Pricing extends Component<Props, State> {
  state = { itemId: null }

  componentDidMount() {
    this.setState({ itemId: this.props.itemId })
  }

  onEnter = (e: SyntheticKeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      // typecast to avoid flow error: https://github.com/facebook/flow/issues/2099
      this.handleChange((e.target: window.HTMLInputElement).value)
    }
  }

  onBlur = (e: SyntheticInputEvent<any>) => {
    this.handleChange(e.target.value)
  }

  handleChange = (value: string) => {
    const { dispatchSetListItemFilter } = this.props
    dispatchSetListItemFilter(value)
  }

  requestPricingColumnsAndRows = (listIdProp: string) => {
    const { spaceId, itemId, pricingType } = this.props
    const addedColumns = [
      {
        propertyName: 'addButtonColumn',
        localizedName: '',
        dataType: ''
      }
    ]
    switch (pricingType) {
      case EQUIPMENT.toLowerCase(): {
        getSpacesEquipmentRegistryColumnsWithSpaceIdRequest({ path: { spaceId } }, { listId: listIdProp, addedColumns })
        getSpacesEquipmentRegistryItemsWithItemIdRequest(
          { path: { itemId, spaceId } },
          { listId: listIdProp, mergeOptions: { initialListItems: {} } }
        )
        break
      }
      case SURFACES.toLowerCase(): {
        getSpacesSurfacesRegistryColumnsWithSpaceIdRequest({ path: { spaceId } }, { listId: listIdProp, addedColumns })
        const mergeOptions = { addedColumnData: { Share: 1 }, initialListItems: {} }
        getSpacesSurfacesRegistryItemsWithItemIdRequest({ path: { itemId, spaceId } }, { listId: listIdProp, mergeOptions })
        break
      }
      default:
        break
    }
  }

  hiddenColumns = (): Array<string> | typeof undefined => {
    switch (this.props.pricingType.toUpperCase()) {
      case EQUIPMENT:
      case SURFACES: return [UNIT_PRICE]
      default: return undefined
    }
  }

  listId(): string {
    const { pricingType } = this.props
    return `${pricingType}-pricing`
  }

  searchField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.searchFieldWrapper}>
        <ListSearchField listId={this.listId()} />
      </div>
    )
  }

  render(): React$Element<any> {
    const {
      t,
      dispatchAddListItem,
      type,
      pricingType
    } = this.props

    return (
      <>
        {this.searchField()}
        <HierarchicalListContainer
          testId={`${pricingType}-pricing`}
          hiddenColumns={this.hiddenColumns()}
          initialColumnWidths={{
            [DESCRIPTION]: 480,
          }}
          editableColumns={[QUANTITY, QUANTITYPCS, SHARE]}
          widgetType={type}
          columnsVisibleOnHover={['addButtonColumn']}
          didMountCallback={(listIdProp: string) => {
            this.requestPricingColumnsAndRows(listIdProp)
          }}
          didUpdateCallback={(prevProps: Object, listContainerProps: Object) => {
            if (this.props.itemId !== this.state.itemId) {
              this.setState({ itemId: this.props.itemId })
              this.requestPricingColumnsAndRows(listContainerProps.listId)
            }
          }}
          listId={this.listId()}
          wrappedCellContents={{
            [DESCRIPTION]: ({ row }: Object) => (
              <OverflowTooltip tooltipText={row.columnData[DESCRIPTION]}>
                {row.columnData[DESCRIPTION]}
              </OverflowTooltip>
            ),
            addButtonColumn: ({ row }: Object) => (
              (row.type.toUpperCase() === PRICEITEM ||
              row.type.toUpperCase() === ASSEMBLY) &&
              <TextButton
                id={row.columnData.Description}
                variant='small'
                text={t('buttons._ADD_')}
                onClick={() => {
                  dispatchAddListItem({
                    ...row,
                    // TODO a better solution will be needed, but currently the receiving list requires
                    // a different set of propertyNames for its columns and what is received in 'row' from the
                    // API here is not accepted.
                    columnData: pricingType === 'surfaces' ? {
                      Description: row.columnData.Description,
                      Quantity: row.columnData.Quantity,
                      ShareOfSurfaceQuantityP: 100,
                      QuantitySpaceSurfaceShareAdjusted: row.columnData.Quantity,
                    } : { ...row.columnData },
                    columnUnits: pricingType === 'surfaces' ? {
                      ...row.columnUnits,
                      ShareOfSurfaceQuantityP: '%',
                      QuantitySpaceSurfaceShareAdjusted: row.columnUnits.Quantity,
                    } : { ...row.columnUnits },
                    // generating id because id is used to create unique row ids
                    // if it was the row's id, we would overwrite the same row
                    id: v4(),
                    registryItemId: row.id,
                })
              }} />
            )
          }} />
      </>
    )
  }
}

function mapDispatchToProps(dispatch: Function, { itemId, pricingListId, pricingType }: ReceivedProps): Object {
  return {
    dispatchAddListItem: (listItem: Object) => { dispatch(addListItem(pricingListId, listItem, itemId)) },
    dispatchSetListItemFilter: (listFilter: string) => { dispatch(setListItemFilter(`${pricingType}-pricing`, listFilter)) },
  }
}

export default connect(null, mapDispatchToProps)(withTranslation('translations')(withStyles(styles)(Pricing)))
