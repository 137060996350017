// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { map, reverse, last, pick } from 'lodash'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { connect } from 'react-redux'
import { typographyClasses, colors } from 'frontend-assets'

import SVGSlider, { valueKeys } from '../Sliders/SVGSlider'
import TextButton from '../../../../common/TextButton/TextButton'
import InputField from '../../../../common/InputField/InputField'
import DropDownContainer from '../../../DropDownContainer/DropDownContainer'
import Switch from '../../../../common/Switch/Switch'

import { renderZero } from '../../../../../utils/commonUtils'
import {
  patchDesignModelStoriesWithStoryIdRequest,
  patchDesignModelWithEstimateIdRequest,
  postDesignModelStoriesWithEstimateIdRequest,
  deleteDesignModelStoriesWithStoryIdRequest
} from '../../../../../utils/generated-api-requests/spaces'
import { postPolling } from '../../../../../actions/postPolling'
import { DROPDOWN_KEYS } from '../../../../../reducers/designModelReducer'
import { PLANNING, FLOOR } from '../../../../../constants/contentTypes'
import { formatValue } from '../../../../../utils/listUtils'
import { FEATURE_DESIGN_MODEL_MANUAL_FEEDING } from '../../../../../constants/features'
import Feature from '../../../Feature/Feature'
import { type PatchDesignModelQueryParams } from '../DesignModelContainer'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'
import {
  TVD_USER_GROUP_TYPE_ID_REAL_ESTATE_ESTIMATE_READ_ONLY
} from '../../../../../constants/userGroupTypeIds'
import {
  MODULE_FEATURE_DESIGN_MODEL_MANUAL_FEEDING,
  MODULE_FEATURE_DESIGN_MODEL_MANUAL_FEEDING_ALTERNATIVE
} from '../../../../../constants/moduleFeatures'
// $FlowFixMe
import { ReactComponent as LockOpenIcon } from '../../../../../../node_modules/frontend-assets/static/assets/images/icons//Other Lock open.svg'
// $FlowFixMe
import { ReactComponent as LockIcon } from '../../../../../../node_modules/frontend-assets/static/assets/images/icons//Other Locked.svg'
// $FlowFixMe
import { ReactComponent as EditIcon } from
  '../../../../../../node_modules/frontend-assets/static/assets/images/icons//Other Editing.svg'

const EDITOR_WIDTH = 493
const { h4 } = typographyClasses
const { dark80 } = colors

const styles = (): Object => ({
  editorWrapper: {
    padding: '34px 20px 0 35px',
    position: 'relative'
  },
  header: {
    ...h4,
    color: dark80,
    padding: '24px 0px 32px 0px'
  },
  editor: {
    width: EDITOR_WIDTH,
    maxWidth: EDITOR_WIDTH,
    position: 'relative'
  },
  architecture: {
    borderTop: 0
  },
  floorRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end', // placeholder rule to make the editor look certain way
    height: 'auto',
    border: `1px solid ${colors.gray80}`,
    borderTop: '1px solid transparent'
  },
  floor: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${colors.gray80}`
  },
  groundConditionsContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    width: '560px'
  },
  groundConditionsRow: {
    ...typographyClasses.bodyDefault,
    color: colors.dark80,
    width: '50%'
  },
  controlsContainer: {
    display: 'flex',
    margin: '0 10px',
    width: '50%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editableContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80px'
  },
  storyNumber: {
    ...typographyClasses.bodyDefault,
    color: colors.dark80,
    fontSize: '14px',
    marginRight: '16px',
    whiteSpace: 'nowrap',
  },
  icon: {
    cursor: 'pointer',
    marginLeft: '5px',
    '&:hover': {
      '& path': {
        fill: colors.black,
      }
    }
  },
  inputFieldWrapper: {
    display: 'flex',
    width: '60px'
  },
  manualFeeding: {
    marginBottom: '32px'
  },
  editorButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 20px 16px 0',
    gap: '24px',
    width: EDITOR_WIDTH,
    maxWidth: EDITOR_WIDTH,
  },
  iconDisabled: {
    cursor: 'initial'
  }
})

export type HorizontalSliderProps = {|
  AreaTotalAlongExteriorWallOutsidesM2: number,
  GrossPerimeterM: number,
  HeightM: number,
  AreaOpeningsHighSpaceAndSlabFactorM2: number,
  PerimeterSlabM: number,
  SectionsPcs: number,
  StoryNumber: number,
  id: string
|}

export type EditorProps = {|
  ManualInput: boolean,
  QualityOfMassing: number,
  QualityOfMaterialsAndDetails: number,
  QualityOfYardArea: number,
  SoilTypeAtFoundationDepth: string,
  SoilTypeAtGroundSurface: string,
  TerrainGradientP: number,
  UseDefaultValues: boolean,
  MassingPerformedByUser: boolean
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // inits post polling
|}

type MappedProps = {|
  userGroupTypeIds: $PropertyType<TVDUserStore, 'userGroupTypeIds'>,
  horizontalSliderMetas: Object, // properties as keys an values as all property data from store
  updateProtectedCirculation?: boolean, // update protected circulation checkbox boolean
  updateStructures?: boolean, // update structures checkbox boolean
  realEstateModuleFeatures: $PropertyType<TVDApplicationStore, 'realEstateModuleFeatures'>, // list of module feature enum ids
  activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>, // if the app is in the midst of a calculating
|}

type Props = {|
  ...DispatchProps,
  ...MappedProps,
  classes: Object, // withStyles classes object
  t: Function, // i18n translate function
  editor: EditorProps, // editor props
  horizontalSliders: Array<HorizontalSliderProps>, // Array of sliders that are rendered as SVGSlider components
  architectureSliders: Array<Object>, // Array of sliders that are rendered as SVGSlider components in architecture section
  manualFeeding: boolean, // manual feeding flag
  disabled: boolean, // flag to disable editor content
  openWidget: Function, // openWidget action
  updateSlider: (string, number, string) => void, // slider update action
  patchSlider: (string, number, string, PatchDesignModelQueryParams) => void, // action to make patch request for new slider value
  toggleSlider: (string) => void, // lock or unlock slider action
  toggleManualFeeding: Function, // toggle manual feeding
  editorRef: TVDRef, // ref for referencing to the editor part of the component
  innerEditorRef: TVDRef, // ref for referencing to the inner editor part of the component
  floorsRef: TVDRef, // ref for referencing to the floors part of the component
|}

export class Editor extends React.Component<Props> {
  static defaultProps = {
    updateProtectedCirculation: false,
    updateStructures: false,
  }

  get floors(): Array<Node> {
    const {
      classes,
      horizontalSliders,
      updateSlider,
      patchSlider,
      manualFeeding,
      disabled,
    } = this.props
    const sliderGroup = 'horizontalSliders'
    const { horizontalSliders: horizontalSliderPropertyName } = valueKeys
    return map(horizontalSliders, (slider: Object, idx: number) => (
      <div className={classes.floor} key={`${slider.id}`} id={`editor-story-${idx + 1}`}>
        {
        this.controlsContainer(
          {
            ...slider,
            StoryNumber: idx + 1,
          },
          idx
        )}
        <SVGSlider
          slider={slider} // slider data
          sliderGroup={sliderGroup}
          disabled={(
            manualFeeding ||
            disabled ||
            this.isReadOnlyProperty(slider.id, horizontalSliderPropertyName)
          )}
          updateSlider={updateSlider}
          patchSlider={patchSlider} />
      </div>
    ))
  }

  get architecture(): React$Element<any> {
    const {
      classes,
      architectureSliders,
      disabled,
      editor
    } = this.props

    return map(architectureSliders, (slider: Object) => (
      <div className={classes.floor} key={`${slider.id}`} >
        {this.architectureContainer(slider)}
        <SVGSlider
          disabled={disabled || editor.MassingPerformedByUser}
          slider={slider} // slider data
          totalArea={4} // Architecture slider uses totalArea as a maximum number of steps, first step = 0, fifth step = 4
          sliderGroup='architectureSliders'
          stepped
          updateSlider={this.props.updateSlider}
          patchSlider={this.props.patchSlider} />
      </div>
    ))
  }

  get groundConditions(): React$Element<any> {
    const {
      classes, t, editor, disabled
    } = this.props
    const updateTerrainGradient = (value: number) => { this.handleEditorChange('TerrainGradientP', value) }

    return (
      <div>
        {this.dropdowns}
        <div className={classes.groundConditionsContainer}>
          <div className={classes.groundConditionsRow}>{t('designModel._TERRAIN_GRADIENT_')}</div>
          <div className={classes.inputFieldWrapper}>
            <InputField
              disabled={disabled}
              width={60}
              id='terrainGradient'
              onChange={updateTerrainGradient}
              initialValue={renderZero(editor.TerrainGradientP)}
              dataType='number'
              unit='%' />
          </div>
        </div>
        <div className={`${classes.groundConditionsContainer} ${classes.manualFeeding}`}>
          <Feature name={FEATURE_DESIGN_MODEL_MANUAL_FEEDING}>
            <div className={classes.groundConditionsRow}>{t('designModel._MANUAL_FEEDING_')}</div>
            <div className={classes.groundConditionsRow}>
              <Switch
                checked={this.props.manualFeeding}
                testId='MANUAL_FEEDING'
                disabled={disabled || this.getIsManualFeedingModuleFeatureDisabled()}
                onChange={this.props.toggleManualFeeding} />
            </div>
          </Feature>
        </div>
      </div>
    )
  }

  get editorButtons(): React$Element<any> {
    const {
      t,
      classes,
      manualFeeding,
      horizontalSliders,
      dispatchPostPolling,
      disabled,
      updateProtectedCirculation,
      updateStructures,
      activeCalculation
    } = this.props
    const query = { updateProtectedCirculation, updateStructures }
    return (
      <div className={classes.editorButtons}>
        <TextButton
          disabled={disabled || manualFeeding}
          text={t('designModel._ADD_STORY_')}
          // iconLeft='add_circle_outline'
          variant='contained'
          onClick={() => {
            postDesignModelStoriesWithEstimateIdRequest({ body: {}, query }, {}, () => {
              dispatchPostPolling()
            })
          }}
          id='add-story' />
        <TextButton
          disabled={manualFeeding || horizontalSliders.length < 2 || disabled || activeCalculation}
          text={t('designModel._DELETE_STORY_')}
          // iconLeft='delete'
          variant='text'
          onClick={() => {
            deleteDesignModelStoriesWithStoryIdRequest({ path: { storyId: last(horizontalSliders).id }, query }, {}, () => {
              dispatchPostPolling()
            })
          }}
          id='delete-story' />
      </div>
    )
  }

  get dropdowns(): React$Element<any> {
    const { classes, editor } = this.props
    const dropdownEnums = pick(editor, DROPDOWN_KEYS)
    return (
      map(dropdownEnums, (dropdownEnum: Object) => (
        <div className={classes.groundConditionsContainer} key={dropdownEnum.propertyName}>
          <div className={classes.groundConditionsRow}>{dropdownEnum.localizedName}</div>
          <div className={classes.groundConditionsRow}>
            <DropDownContainer
              disabled={this.props.disabled}
              requestDefinition={dropdownEnum.enumRequestDefinitions}
              defaultValue={dropdownEnum.value}
              id={dropdownEnum.propertyName}
              onDropDownChange={(value: string) => this.handleEditorChange(dropdownEnum.propertyName, value)} />
          </div>
        </div>
      )))
  }


  getIsManualFeedingModuleFeatureDisabled = (): boolean => {
    const { realEstateModuleFeatures } = this.props
    return !!(
      !realEstateModuleFeatures.includes(MODULE_FEATURE_DESIGN_MODEL_MANUAL_FEEDING) &&
      !realEstateModuleFeatures.includes(MODULE_FEATURE_DESIGN_MODEL_MANUAL_FEEDING_ALTERNATIVE)
    )
  }

  isReadOnlyProperty = (sliderId: string, propertyName: string): boolean => {
    const { horizontalSliderMetas } = this.props
    const sliderMeta = horizontalSliderMetas[sliderId]
    const { readOnly } = sliderMeta && sliderMeta[propertyName] ? sliderMeta[propertyName] : {}
    return readOnly
  }

  handleFormatting = (value: number): number | string => {
    const formattedValue = formatValue(value, 'double')
    if (typeof formattedValue === 'number') return formattedValue / 100
    return formattedValue
  }

  handleEditorChange = (propertyName: string, value: string | number): void => {
    const { updateProtectedCirculation, updateStructures } = this.props
    patchDesignModelWithEstimateIdRequest({
      body: { [propertyName]: value },
      query: {
        updateProtectedCirculation,
        updateStructures
      }
    }, {}, () => {
      this.props.dispatchPostPolling()
    })
  }

  architectureContainer(slider: Object): React$Element<any> {
    const { classes, t } = this.props
    return (
      <div className={classes.controlsContainer}>
        <div className={classes.editableContainer}>
          <span className={classes.storyNumber}>{t(`designModel._${slider.name}_`)}</span>
        </div>
      </div>
    )
  }

  controlsContainer(
    slider: Object,
    idx: number,
  ): React$Element<any> {
    const {
      classes,
      manualFeeding,
      toggleSlider,
      disabled,
      dispatchPostPolling,
      updateProtectedCirculation,
      updateStructures,
      userGroupTypeIds
    } = this.props
    const query = { updateProtectedCirculation, updateStructures }
    const toggleSliderRelationLock = () => toggleSlider(slider)
    const openStoryWidget = (contentProps: Object, sliderId: string) => {
      const childContentProps = { ...contentProps, widgetLevelChild: true }
      this.props.openWidget(childContentProps, sliderId, FLOOR, slider.StoryNumber)
    }
    const updateSliderValue = (value: number) => {
      const formattedValue = this.handleFormatting(value)
      if (!manualFeeding) {
        // update endpoint, fetch new values & calculate slider relations
        patchDesignModelStoriesWithStoryIdRequest({
          path: { storyId: slider.id },
          body: { RatioSpaceArea: formattedValue },
          query
        }, {}, () => {
          dispatchPostPolling()
        })
      } else {
        // update endpoint, fetch new values & calculate slider relations
        const propertyName = manualFeeding ? 'AreaTotalAlongExteriorWallOutsidesNoLargeOpeningsM2' : 'AreaTotalAlongExteriorWallOutsidesM2'
        patchDesignModelStoriesWithStoryIdRequest({
          path: { storyId: slider.id },
          // $FlowFixMe
          body: { [propertyName]: formattedValue },
          query,
        }, {}, () => {
          dispatchPostPolling()
        })
      }
    }
    const iconStyles = { fontSize: '20px' }
    const unit = manualFeeding ? 'm2' : '%'
    const lockIcon = slider.RatioIsLocked ? <LockIcon /> : <LockOpenIcon />
    const contentProps = { spaceId: PLANNING, description: slider.StoryNumber, resourceId: slider.id }
    const presentValueAs = manualFeeding
      ? parseFloat(slider.AreaTotalAlongExteriorWallOutsidesNoLargeOpeningsM2).toFixed(2) // show as m2 with 2 decimal accuracy
      : parseFloat(slider.RatioSpaceArea * 100).toFixed(2) // show as percentage
    const propertyName = manualFeeding ? 'AreaTotalAlongExteriorWallOutsidesNoLargeOpeningsM2' : 'RatioSpaceArea'
    const disabledStory = userGroupTypeIds.includes(TVD_USER_GROUP_TYPE_ID_REAL_ESTATE_ESTIMATE_READ_ONLY) || !disabled

    return (
      <div className={classes.controlsContainer}>
        <div className={classes.editableContainer}>
          <span className={classes.storyNumber}>{slider.StoryNumber}.</span>
          <InputField
            disabled={disabled || this.isReadOnlyProperty(slider.id, propertyName) || manualFeeding}
            id={`story-${idx}`}
            onChange={updateSliderValue}
            initialValue={presentValueAs}
            dataType='number'
            unit={unit} />
        </div>
        <div>
          {!this.props.manualFeeding &&
            <Icon
              style={iconStyles}
              id={`LOCK_ICON_${slider.StoryNumber}`}
              color={disabled ? 'disabled' : 'primary'}
              className={combineStyleClassNames(classes.icon, disabled && classes.iconDisabled)}
              onClick={!disabled && toggleSliderRelationLock}>{lockIcon}
            </Icon>
          }
          <Icon
            style={iconStyles}
            color={!disabledStory ? 'disabled' : 'primary'}
            className={combineStyleClassNames(classes.icon, !disabledStory && classes.iconDisabled)}
            data-testid={`STORY_ICON_${slider.StoryNumber}`}
            onClick={() => disabledStory && openStoryWidget(contentProps, slider.id)}>
            <EditIcon />
          </Icon>
        </div>
      </div>
    )
  }

  render(): React$Element<'div'> {
    const {
      t,
      classes,
      horizontalSliders,
      editorRef,
      innerEditorRef,
      floorsRef
    } = this.props
    return (
      <div ref={editorRef} className={classes.editorWrapper} id='Editor'>
        <div className={classes.header}>{t('designModel._EDITOR_HEADER_')}</div>
        <div ref={innerEditorRef} className={classes.editor}>
          {this.groundConditions}
          <div ref={floorsRef} className={classes.floorRow}>{reverse(this.floors)}</div>
        </div>
        {horizontalSliders && this.editorButtons}
        <div className={classes.header}>{t('designModel._ARCHITECTURE_HEADER_')}</div>
        <div className={`${classes.editor} ${classes.architecture}`}>
          <div className={classes.floorRow}>{this.architecture}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, designModel, user: { userGroupTypeIds } }: TVDReduxStore): MappedProps => {
  const {
    realEstateModuleFeatures,
    activeCalculation
  } = app
  const {
    horizontalSliderMetas,
    options: {
      updateProtectedCirculation,
      updateStructures,
    }
  } = designModel
  return {
    horizontalSliderMetas,
    updateProtectedCirculation,
    updateStructures,
    userGroupTypeIds,
    realEstateModuleFeatures,
    activeCalculation
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchPostPolling: () => dispatch(postPolling())
})

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Editor)))
