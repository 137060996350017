// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { map, filter, get, includes, isEmpty } from 'lodash'
import { withStyles, TableRow, TableCell, TableHead, Icon } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import { typographyClasses } from 'frontend-assets'

import ListRowCell from '../common/ListRowCell/ListRowCell'
import ListOpenerIcon from '../common/ListOpenerIcon/ListOpenerIcon'
import ListHeaderCell from '../common/ListHeaderCell/ListHeaderCell'
import Checkbox from '../../CheckBox/CheckBox'
import ContextMenu from '../../menus/ContextMenu/ContextMenu'
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip'
import Unit from '../../Unit/Unit'

import { formatCellContent } from '../../../../utils/listUtils'
import {
  childWidgets,
  listColumnUnitsToHide,
  MATRIX_LIST_LEFT,
  CREATE_FROM_PRICING,
  ACTIVITY_REGISTRY_WIDGET
} from '../../../../constants/contentTypes'
import {
  AMOUNT,
  QUANTITY,
  DESCRIPTION,
  RENOVATION_MEASURE_ID,
  RENOVATION_PROFILE_ID,
  DRIVER,
  SPACE_DRIVER
} from '../../../../constants/attributes'
import { SPACES, ELEMENTS } from '../../../../constants/moduleConstants'
import { combineStyleClassNames } from '../../../../utils/styleUtils'
import theme from '../../../../styles/theme'

export const outerWrappedContentContainerTestId = 'outer-wrapped-content-container'

const styles = ({ palette }: Object): Object => ({
  matrixtable: {
    display: 'table',
    overflow: 'auto',
  },
  table: {
    display: 'block',
    overflow: 'auto',
    height: '100%'
  },
  headerCell: {
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.classes.bodyTiny,
    color: palette.dark100,
    justifyContent: 'flex-end'
  },
  rowCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: palette.dark80,
  },
  topLevelDescriptionStyles: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    marginLeft: 0,
    color: palette.dark80,
    fontSize: 16,
    overflow: 'hidden'
  },
  columnSpannerCell: {
    width: '100%',
    borderBottom: `1px solid ${palette.gray40}`,
  },
  headerSpannerCell: {
    backgroundColor: palette.white,
    top: 0,
    position: 'sticky',
  },
  blank: {
    color: palette.gray120,
    marginLeft: '4px',
    fontSize: '15px',
  },
  checked: {
    marginLeft: '4px',
    fontSize: '15px',
    color: palette.primary100
  },
  descriptionHeaderCell: {
    justifyContent: 'flex-start',
    paddingLeft: 35,
  },
  listOpenerIconWrapper: {
    marginRight: 10
  },
  cellLeft: {
    marginLeft: 10,
  },
  row: {
    height: '40px',
    alignItems: 'center',
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible',
    },
    '&:hover': {
      backgroundColor: palette.gray20,
    },
    '&:active': {
      backgroundColor: palette.primary20,
    },
  },
  activeFilterSource: {
    backgroundColor: palette.primary20 // Need to ask UI-UX later about the correct color
  },
  disabled: {
    color: palette.dark60,
    pointerEvents: 'none'
  },
  wrappedCellContent: {
    width: '100%',
    overflow: 'hidden'
  },
  wrappedCellContentWithUnit: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  unit: {
    paddingLeft: 5
  },
  listRowCellInnerWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  noLastHeaderCellPadding: {
    paddingRight: '0px !important',
  },
  hidden: {
    visibility: 'hidden'
  },
  wrappedCellAllowOverflow: {
    overflow: 'initial'
  },
  highlightedRow: {
    backgroundColor: palette.gray40
  },
  disableHoverEffects: {
    pointerEvents: 'none'
  },
  bodyNumbers: {
    ...typographyClasses.bodyNumbers,
  }
})

type Props = {|
  scrollPosition?: number, // scroll position of the list
  id: string, // id used to identify the list
  testId: string, // testid used to identify list rows and cells
  listType: string, // type or name of a list
  data: Array<TVDListItem>, // Data object that contains row objects
  classes: Object, // classes object generated by withStyles function
  onRowClick: Function, // function bound to row click event
  onCheckBoxClick: Function, // function bound to checkbox click event
  displayCheckBoxes?: boolean, // flag to display checkbox for each row
  displayCheckBoxesAlways?: boolean, // flag to display checkbox for each row without hover
  checkboxDisabled?: Function | boolean, // specifically disable checkbox
  columns: Array<Object>, // Array of list column objects
  editableColumnUnits: Array<string>, // array of editable column names that should display unit
  disabled: boolean, // flag to disable list events
  wrappedCellContents: Object, // Object for cells that use inputfield
  columnsVisibleOnHover: Array<string>, // column property names that are shown when row is hovered
  widgetType: ?string, // widgetType if provided
  contextMenuItems: Object, // ContextMenu items
  listHeaderCellClassNames?: { [string]: string }, // object of classNames that is passed to customize listHeaderCell
  resizeDisabledColumns?: Array<string>, // column propertyNames that will not have resizable handle
  listRowCellClassNames?: { [string]: string }, // object of classNames that is passed to customize listRowCell
  editableColumns?: {[key: string]: Function }, // names of columns that can be edited
  editableColumnCheck?: (TVDListItem, TVDListItemColumn) => boolean, // optional check up fn to determine if a column should render as an editable one
  disableSpanners?: boolean, // to disable spanners from header and row cells
  hideListOpenerIcon?: boolean, // does not render list opener icon
  allowOverflow?: boolean, // allows wrapped cell content to have overflowing content e.g :before and :after css effects
  initialColumnWidths: { [columnName: string]: string | number }, // assign initial column widths based on the column name
  onUnitClick?: (row: TVDListItem, column: TVDListItemColumn) => Function, // a way to retrieve what should be ran if unit of a particular row and cell is editable
  activeFilterSource: Object, // filter and marker for wopActivitySchedule/wopSpaceSchedule filtering source list
  allowOnRowClick?: boolean, // flag to allow user to click on the row
  disableHoverEffects?: boolean, // disabled all hover effects e.g background styling
  onScrollCb?: (scrollTop: number) => void // callback function for onScroll event that provides access to the scroll position
|}

type State = {|
  contextMenu: Object, // ContextMenu content
|}

export class SimpleHierarchicalList extends React.Component<Props, State> {
  static defaultProps = {
    onRowClick: null,
    onCheckBoxClick: null,
    id: 'undefined',
    testId: 'missing-testid',
    resizeDisabledColumns: [],
    listHeaderCellClassNames: {},
    listRowCellClassNames: {},
    editableColumnCheck: undefined,
    editableColumns: {},
    displayCheckBoxes: false,
    disableSpanners: false,
    hideListOpenerIcon: false,
    checkboxDisabled: false,
    allowOverflow: false,
    initialColumnWidths: {},
    onUnitClick: undefined,
    displayCheckBoxesAlways: false,
    allowOnRowClick: false,
    disableHoverEffects: false
  }

  state = {
    contextMenu: {}
  }

  tableRef: any = React.createRef()

  componentDidUpdate() {
    const { scrollPosition } = this.props
    if (!isNaN(scrollPosition) && this.tableRef.current) {
      setTimeout(() => {
        this.tableRef.current.scrollTop = scrollPosition
      }, 0)
    }
  }

  get columnSpanner(): React$Element<typeof TableCell> {
    const { classes } = this.props
    return <TableCell className={classes.columnSpannerCell} />
  }

  get headerSpanner(): React$Element<typeof TableCell> {
    const { classes } = this.props
    return <TableCell className={`${classes.columnSpannerCell} ${classes.headerSpannerCell}`} />
  }

  get columns(): Array<React$Element<any>> {
    const {
      classes,
      columns,
      displayCheckBoxes,
      listHeaderCellClassNames = {},
      resizeDisabledColumns = [],
      disableSpanners,
    } = this.props
    const cols = map(columns, (column: Object, index: number | string) => {
      const parsedIndex = parseInt(index, 10)
      const { propertyName } = column
      const innerWrapperClassName = combineStyleClassNames(propertyName === DESCRIPTION && classes.descriptionHeaderCell, classes.headerCell)

      return (
        <ListHeaderCell
          resizeDisabled={resizeDisabledColumns.includes(propertyName)}
          cellClassName={combineStyleClassNames(disableSpanners && classes.noLastHeaderCellPadding, listHeaderCellClassNames[propertyName])}
          style={{ paddingLeft: (propertyName === DESCRIPTION) && displayCheckBoxes ? 0 : '22px' }}
          key={parsedIndex}
          propertyName={propertyName}
          initialWidth={this.getInitialWidth(parsedIndex, column)}
          variant='head'>
          <div className={innerWrapperClassName}>
            <OverflowTooltip tooltipText={column.localizedName}>
              { column.localizedName }
            </OverflowTooltip>
          </div>
        </ListHeaderCell>
      )
    })
    if (displayCheckBoxes) {
      cols.unshift(<ListHeaderCell key='checkbox' propertyName='checkbox' initialWidth={0}><div className={classes.headerCell} /></ListHeaderCell>)
    }
    return cols
  }

  get rows(): Array<React$Element<any>> {
    const {
      data,
      displayCheckBoxes,
      classes,
      testId,
      contextMenuItems,
      disableSpanners,
      activeFilterSource,
      disableHoverEffects
    } = this.props

    return map(data, (row: Object, rowIndex: number) => {
      const openContextMenu = (e: SyntheticMouseEvent<any>) => {
        if (typeof contextMenuItems === 'function') {
          e.persist()
          e.preventDefault()
          this.setState({
            contextMenu: {
              items: contextMenuItems(row),
              location: { x: e.pageX, y: e.pageY }
            }
          })
        }
      }

      // $FlowFixMe: In this case, row is dynamic so we can't declare ref beforehand
      this[`ref-${row.id}`] = React.createRef()

      return (
        <TableRow
          // $FlowFixMe: In this case, row is dynamic so we can't declare ref beforehand
          ref={this[`ref-${row.id}`]}
          data-testid={`simple-list-row-${testId}-${row.id}-${get(row, ['columnData', DESCRIPTION])}`}
          id={`simple-list-row-${testId}-${row.id}-${get(row, ['columnData', DESCRIPTION])}`}
          className={combineStyleClassNames(
            classes.row,
            (this.isActiveSource() && row.id === activeFilterSource.id) && classes.activeFilterSource,
            disableHoverEffects && classes.disableHoverEffects
          )}
          classes={{ root: classes.row }}
          key={row.id}
          onMouseDown={() => {
            if (window.getSelection().type === 'Range') window.getSelection().removeAllRanges()
          }}
          onClickCapture={(event: Object) => {
            if (window.getSelection().type === 'Range') event.stopPropagation()
          }}
          onClick={() => this.handleRowClick(row, rowIndex)}
          onContextMenu={openContextMenu}>
          { displayCheckBoxes && this.getCheckbox(row, this.getParentRow(row)) }
          { this.getCells(row, rowIndex) }
          { !disableSpanners && this.columnSpanner }
        </TableRow>
      )
    })
  }

  handleRowClick = (row: Object, rowIndex: number): void => {
    const { allowOnRowClick, onRowClick, disabled } = this.props

    if (onRowClick) {
      if (allowOnRowClick || !disabled) {
        onRowClick(row, rowIndex)
      }
    }
  }

  isActiveSource = (): boolean => !isEmpty(this.props.activeFilterSource)

  handleCheckboxDisabled = (row: TVDListItem): boolean => {
    const { checkboxDisabled, disabled } = this.props
    if (typeof checkboxDisabled === 'function') return checkboxDisabled(row)
    return row.checkboxDisabled || checkboxDisabled || disabled
  }

  getInitialWidth = (parsedIndex: number, { propertyName, unit }: TVDListItemColumn) => {
    const {
      widgetType,
      listType,
      initialColumnWidths,
    } = this.props
    const firstCellWidth = childWidgets.includes(widgetType) ? 200 : 300
    const initialWidthProp = initialColumnWidths[propertyName]
    if (initialWidthProp) return initialWidthProp
    if (unit) {
      return 102
    }
    // This fixes a bug where slider is not fully visible until column is expanded
    // Use initialColumnWidths prop instead of this
    // TODO: refactor the below cases to use initialColumnWidths
    if (propertyName === RENOVATION_MEASURE_ID) return 150
    if (propertyName === RENOVATION_PROFILE_ID) return 170
    if (propertyName === DRIVER) return 140
    if (propertyName === SPACE_DRIVER) return 220

    // FIX this SPACES/ELEMENTS list specialty does not really belong here, fix for a more maintainable solution 4.3.2020 VR
    if (parsedIndex === 0 && (listType === SPACES || listType === ELEMENTS)) return 400
    if (parsedIndex === 0 && listType === CREATE_FROM_PRICING) {
      return 560
    }
    if (parsedIndex === 0 && listType === ACTIVITY_REGISTRY_WIDGET) {
      return 630
    }
    return parsedIndex === 0 ? firstCellWidth : 100
  }

  getColumnByCellIndex = (cellIndex: number) => {
    const { columns } = this.props
    return filter(columns, (column: Object, columnIndex: number) => columnIndex === cellIndex)[0]
  }

  getHierarchicalStyles = (row: Object, isDescriptionCell: boolean, showListOpenerIcon: boolean) => {
    const { classes, disabled } = this.props
    const cellLeft = !showListOpenerIcon ? classes.cellLeft : ''
    let cellStyle = ''

    if (row.level === 0 && isDescriptionCell) {
      cellStyle = `${classes.topLevelDescriptionStyles} ${cellLeft} `
    } else if (isDescriptionCell) {
      cellStyle = ` ${classes.rowCell} ${cellLeft}`
    }

    return disabled ? `${cellStyle} ${classes.disabled}` : cellStyle
  }

  getMarginLeft = (showListOpenerIcon: boolean, level: number, propertyName: string) => {
    switch (propertyName) {
      case AMOUNT:
        return 25
      case DESCRIPTION:
        return (level * 15)
      default:
        return 0
    }
  }

  static getCellContent(columnData: Object = {}, propertyName: string): any {
    if (propertyName === QUANTITY && !columnData[propertyName]) {
      return columnData[QUANTITY]
    }
    return columnData[propertyName]
  }

  getParentRow(row: Object): Object {
    return this.props.data.find((listRow: Object) => listRow.id === row.parentId)
  }

  getCheckbox(row: Object, parentRow: Object = {}): React$Element<any> {
    const {
      classes, disabled, onCheckBoxClick, displayCheckBoxesAlways
    } = this.props

    const showOnHover = (!displayCheckBoxesAlways && !row.selected && !parentRow.selected) || undefined

    return (
      <ListRowCell
        id={`CheckBox-${row.columnData ? row.columnData.Description : row.description}`}
        alignRight
        style={{ minWidth: '38px' }}>
        {
          row.type?.toLowerCase() !== 'nonloadbearingstructure' &&
          <div data-visible_on_hover={showOnHover}>
            <Checkbox
              stopPropagation
              onChange={() => (onCheckBoxClick && !disabled) && onCheckBoxClick(row)}
              disabled={this.handleCheckboxDisabled(row)}
              id={row.columnData ? row.columnData.Description : row.description}
              icon={<Icon className={classes.blank}>check_box_outline_blank</Icon>}
              checkedIcon={<Icon className={classes.checked}>check_box</Icon>}
              checked={row.selected || false} />
          </div>
        }
      </ListRowCell>
    )
  }

  getContentFunction(propertyName: string, row: TVDListItem, column: TVDListItemColumn): Function | null {
    const {
      editableColumns = {},
      editableColumnCheck,
      wrappedCellContents,
    } = this.props

    switch (true) {
      case !!(wrappedCellContents && wrappedCellContents[propertyName]): {
        return wrappedCellContents[propertyName]
      }
      case !!(editableColumns[propertyName] && !editableColumnCheck):
      case !!(editableColumns[propertyName] && editableColumnCheck && editableColumnCheck(row, column)): {
        return editableColumns[propertyName]
      }
      default:
        return null
    }
  }

  getCellUnit(row: TVDListItem, column: TVDListItemColumn): React$Element<typeof Unit> {
    const { onUnitClick, disabled } = this.props
    const onClick = onUnitClick ? onUnitClick(row, column) : undefined
    const { propertyName, unit } = column
    const { columnUnits: { [propertyName]: rowUnit } = {} } = row
    const unitValue = rowUnit || unit
    return <Unit value={unitValue} testId={propertyName} onClick={onClick} disabled={disabled} />
  }

  getWrappedCellContent(
    index: number,
    cellContent: any,
    rowIndex: number,
    row: Object,
    cellUnit: ?string,
    column: TVDListItemColumn
  ): any {
    const {
      classes,
      editableColumnUnits,
      allowOverflow,
    } = this.props
    const { propertyName, dataType } = this.getColumnByCellIndex(index)

    const className = combineStyleClassNames(
      classes.wrappedCellContent,
      cellUnit && classes.wrappedCellContentWithUnit,
      (allowOverflow && propertyName !== DESCRIPTION) && classes.wrappedCellAllowOverflow,
      (dataType === 'number' || dataType === 'integer') && classes.bodyNumbers
    )

    const wrappedContent = this.getContentFunction(propertyName, row, column)
    const isCategoryRow = row.type === 'item'
    const cellContentIsZero = cellContent === 0

    const determineWrappedCellHasUnit = () => {
      if (includes(editableColumnUnits, propertyName) && cellContentIsZero) return true
      if (!cellUnit) return false
      if (isCategoryRow && !cellContent) return false
      if (isCategoryRow && cellContentIsZero) return false
      if (!isCategoryRow && (!cellContent && cellContent !== 0)) return false
      return true
    }
    return wrappedContent
      ? (
        <div data-testid={outerWrappedContentContainerTestId} className={className}>
          {
            wrappedContent({
              content: cellContent,
              rowIndex,
              row,
              dataType,
              column,
              // $FlowFixMe: In this case, row is dynamic so we can't declare ref beforehand
              rowRef: this[`ref-${row.id}`],
              highlightedRow: classes.highlightedRow
            })
          }
          { determineWrappedCellHasUnit() && this.getCellUnit(row, column)}
        </div>
      )
      : (
        !cellContentIsZero &&
        <div className={className}>
          {formatCellContent(cellContent, dataType)}
          { (cellUnit !== undefined) && this.getCellUnit(row, column)}
        </div>
      )
  }

  getCells(row: Object, rowIndex: number): Array<React$Element<any>> {
    const {
      columns,
      classes,
      columnsVisibleOnHover = [],
      disabled,
      displayCheckBoxes,
      listRowCellClassNames = {},
      hideListOpenerIcon,
    } = this.props
    return map(columns, (column: Object, index: number) => {
      const { propertyName: columnIdentifier } = column
      const indexNo = parseInt(index, 10)
      const showListOpenerIcon = columnIdentifier === DESCRIPTION && !!row.canHaveChildren
      const renderListOpenerIcon = columnIdentifier === DESCRIPTION && !hideListOpenerIcon
      const cellContent = SimpleHierarchicalList.getCellContent(row.columnData, columnIdentifier)
      const rowDescription = row.columnData ? row.columnData.Description : row.description
      const { propertyName } = this.getColumnByCellIndex(index)

      const columnUnitsToHide = listColumnUnitsToHide[this.props.listType]
      const hideUnit = includes(columnUnitsToHide, propertyName) || includes(columnUnitsToHide, 'all')
      const cellUnit = hideUnit ? undefined : (row.columnUnits && row.columnUnits[columnIdentifier]) || column.unit
      const visibleOnHover = columnsVisibleOnHover.includes(columnIdentifier) ? 'true' : null

      return (
        <ListRowCell
          cellClassName={listRowCellClassNames[columnIdentifier]}
          disabled={disabled}
          style={{ paddingLeft: !displayCheckBoxes ? '22px' : 0, position: 'static' }}
          alignRight={columnIdentifier !== DESCRIPTION}
          id={`${columnIdentifier}-${rowDescription}`}
          level={row.level}
          hasChildren={row.canHaveChildren}
          column={columnIdentifier}
          key={`${indexNo}${showListOpenerIcon.toString()}`}>
          <div
            style={{ marginLeft: this.getMarginLeft(showListOpenerIcon, row.level, columnIdentifier), position: 'relative' }}
            {...{ 'data-visible_on_hover': visibleOnHover }}
            className={this.getHierarchicalStyles(row, columnIdentifier === DESCRIPTION, showListOpenerIcon)}>
            {
              renderListOpenerIcon ?
                <div
                  data-testid={`ListOpenerIcon-${rowDescription}`}
                  className={combineStyleClassNames(classes.listOpenerIconWrapper, !showListOpenerIcon && classes.hidden)}>
                  <ListOpenerIcon id='ListOpenerIcon' position='relative' isOpen={row.isOpen} />
                </div>
                : null
            }
            {this.getWrappedCellContent(index, cellContent, rowIndex, row, cellUnit, column)}
          </div>
        </ListRowCell>
      )
    })
  }

  render(): React$Element<any> {
    const {
      classes, disableSpanners, id, onScrollCb
    } = this.props
    const { contextMenu } = this.state
    return (
      <React.Fragment>
        <Table
          ref={this.tableRef}
          onScroll={(event: SyntheticEvent<HTMLTableElement>) => {
            if (onScrollCb) {
              onScrollCb(event.currentTarget.scrollTop)
            }
          }}
          className={id === MATRIX_LIST_LEFT ? classes.matrixtable : classes.table}
          size='small'>
          <TableHead>
            <TableRow>
              {this.columns}
              {!disableSpanners && this.headerSpanner}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.rows}
          </TableBody>
          {
            id === MATRIX_LIST_LEFT &&
            <TableFooter>
              <TableRow><TableCell style={{ borderBottom: 0, height: 20 }} /></TableRow>
            </TableFooter>
          }
        </Table>
        {
          (!isEmpty(contextMenu) && !isEmpty(contextMenu.items)) &&
          <ContextMenu
            id='ContextMenu'
            items={contextMenu.items}
            location={contextMenu.location}
            handleClose={() => this.setState({ contextMenu: {} })} />
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(SimpleHierarchicalList)
