// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import type { Node } from 'react'
import { withTranslation } from 'react-i18next'
import { map, find, reduce } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

import TextButton from '../../../common/TextButton/TextButton'
import type { TextButtonProps } from '../../../common/TextButton/TextButton'
import { dateToday } from '../../../../utils/datepickerUtils'
import { postEstimatesRequest, getEstimatesRequest, getBuildingsRequest } from '../../../../utils/generated-api-requests/estimates'
import DropdownSearchMenu from '../../../common/menus/DropdownSearchMenu/DropdownSearchMenu'

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '15px',
    paddingLeft: '43px'
  },
  dropdown: {
    margin: '15px',
    minWidth: '120px',
    maxWidth: '300px'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '7px 32px 24px 0px'
  },
}

type Props = {|
  t: (string) => string, // i18next translation function
  classes: Object, // withStyles classes object
  app: string, // app which "owns" the modal
  theme: Object, // withStyles theme object,
  selectedBuildingId: string, // ID of selected building
  importCb: (TVDRequestResponse) => void, // callBack function executed after import request is completed while having response as param
  userId: $PropertyType<TVDUserClaims, 'userId'>, // users guid
|}

type State = {|
  buildings: Array<Object>,
  calculations: Array<Object>,
  buildingDropdown: string, // currently selected building
  calculationDropdown: string // currently selected calculation
|}

export class Import extends React.PureComponent<Props, State> {
  state = {
    buildings: [],
    calculations: [],
    buildingDropdown: '',
    calculationDropdown: ''
  }

  componentDidMount() {
    const { userId } = this.props
    getBuildingsRequest({ query: { permissionsSubject: userId } }, {}, (buildings: Array<Object>) => { this.setState({ buildings }) })
  }

  componentDidUpdate(prevProps: Object, prevState: Object) {
    if (this.state.buildingDropdown && (prevState.buildingDropdown !== this.state.buildingDropdown)) {
      this.getEstimates()
    }
  }

  getEstimates = () => {
    const { app: estimateType } = this.props
    const buildingId = find(this.state.buildings, { id: this.state.buildingDropdown }).id
    getEstimatesRequest({ query: { estimateType, buildingId } }, { shouldSkipUpdate: true }, (calculations: Object) => {
      const calcs = map(calculations[estimateType], (calculation: Object) => calculation)
      this.setState({ calculations: calcs })
    })
  }

  buildingDropdown(): React.Element<FormControl> {
    const {
      t,
      classes,
      selectedBuildingId,
    } = this.props

    const items = reduce(this.state.buildings, (result: Array<TVDMenuItem>, building: Object) => {
      if (building.id !== selectedBuildingId) result.push({ value: building.id, localizedName: building.buildingName })
      return result
    }, [])

    const onChange = (selection: string) => {
      this.setState({ buildingDropdown: selection, calculationDropdown: '' }, () => {
        this.getEstimates()
      })
    }

    const onClear = () => { this.setState({ buildingDropdown: '', calculations: [], calculationDropdown: '' }) }

    return (
      <div className={classes.dropdown}>
        <DropdownSearchMenu
          width='XXXL'
          onChange={onChange.bind(this)}
          onClear={onClear}
          title={t('createCalculation._BUILDING_')}
          items={items} />
      </div>
    )
  }

  calculationDropdown(): React.Element<FormControl> {
    const { t, classes } = this.props
    const { calculations, calculationDropdown } = this.state
    const items = map(calculations, (calculation: Object) => ({
      value: calculation.id,
      localizedName: calculation.description
    }))
    const onChange = (selection: string) => this.setState({ calculationDropdown: selection })
    const onClear = () => { this.setState({ buildingDropdown: '' }) }
    return (
      <div className={classes.dropdown}>
        <DropdownSearchMenu
          width='XXXL'
          value={calculationDropdown}
          onChange={onChange.bind(this)}
          onClear={onClear}
          title={t('createCalculation._CALCULATION_')}
          items={items} />
      </div>
    )
  }

  buttonsContainer(): React$Element<any> {
    const {
      t, classes, selectedBuildingId, importCb
    } = this.props

    const { calculationDropdown } = this.state

    const onClick = () => {
      const importedCalculationData = {
        calculationDropdown,
        modifiedDate: dateToday(),
        createdDate: dateToday(),
      }
      postEstimatesRequest({
        body: importedCalculationData,
        query: {
          parentId: selectedBuildingId,
          importId: calculationDropdown
        }
      }, {}, (res: TVDRequestResponse) => {
        importCb(res)
      })
    }

    const props: TextButtonProps = {
      onClick,
      text: t('createCalculation._IMPORT_'),
      id: 'Import-button',
      disabled: (!this.state.buildingDropdown || !this.state.calculationDropdown)
    }

    return (
      <div className={classes.buttonsContainer}>
        <TextButton {...props} />
      </div>
    )
  }

  render(): Node {
    const { classes } = this.props
    return (
      <React.Fragment>
        <div className={classes.content}>
          {this.buildingDropdown()}
          {this.calculationDropdown()}
        </div>
        {this.buttonsContainer()}
      </React.Fragment>
    )
  }
}

export default withTranslation('translations')(withStyles(styles, { withTheme: true })(Import))
