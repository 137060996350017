// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { round } from 'lodash'
import { Icon } from '@material-ui/core'

import SimpleDataTable from '../../../common/lists/SimpleDataTable/SimpleDataTable'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'

import { MODAL_TYPE_CALCULATION_INFORMATION } from '../../../../constants/modalConstants'
import { CONFIRMATION_MODAL } from '../../../../constants/contentTypes'
import { getEstimateHistoryObj } from '../../../../utils/urlUtil'
import { formattedDate } from '../../../../utils/commonUtils'
import { openModal, closeModal } from '../../../../actions/modals'
import { copyCalculation } from '../../../../actions/calculations'
import { setCalculation, setEstimateName, setBuildingName } from '../../../../actions/app'
import { SPACES, WOP, ELEMENTS, LOCAN } from '../../../../constants/moduleConstants'
import { runAllGARs } from '../../../../utils/GARUtils'

import {
  getEstimateLockWithEstimateIdRequest as getEstimateLockWithEstimateIdRequestSpaces,
  putEstimateLockWithEstimateIdRequest,
  deleteEstimateLockWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/spaces'
import {
  deleteEstimatesWithEstimateIdRequest, getEstimatesRequest
} from '../../../../utils/generated-api-requests/estimates'
import {
  getEstimateLockWithEstimateIdRequest as getEstimateLockWithEstimateIdRequestElements
} from '../../../../utils/generated-api-requests/buildingelements'
import {
  getEstimateLockWithEstimateIdRequest as getEstimateLockWithEstimateIdRequestWop
} from '../../../../utils/generated-api-requests/wop'
import {
  getEstimateLockWithEstimateIdRequest as getEstimateLockWithEstimateIdRequestLocan
} from '../../../../utils/generated-api-requests/locan'
import { MANAGER, OWNER } from '../../../../constants/permissions'

const styles = ({ palette }: TVDTheme) => ({
  tableWrapper: {
    overflowX: 'hidden',
    marginBottom: '37px'
  },
  hamburgerWrapper: {
    paddingLeft: '5px'
  },
  lockIcon: {
    fontSize: '19px',
    color: palette.cadetBlue,
    marginLeft: '5px'
  },
  descriptionWrapper: {
    display: 'flex',
  }
})

type TVDLock = {|
  nonEmbedded: TVDLock, // TODO: parser issues with lock requests - there should not be lock details in double
  createDate: string, // created timestamp e.g "2020-04-03T08:38:05.26266Z"
  userId: string, // user id in uuid format
  resourceId: string, // a resource id in uuid format
  expirationSeconds: number, // the total expiration seconds that lock can have e.g 1800
  isExpired: boolean, // if the lock is expired
  secondsRemaining: number, // remaining seconds until lock is no longer active e.g 398.9347431000001
|}

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // with styles object
  history: Object, // withRouter history object
|}

type DispatchProps = {|
  dispatchSetCalculation: Function, // dispatched function to set calculation id
  dispatchSetEstimateName: (string) => void, // Function to set chosen estimate name to store
  dispatchCopyCalculation: (id: string, appKey: string, buildingId: string) => void, // dispathced function to copy calculation
  dispatchCloseModal: (modalId: string) => void, // close modal fn
  dispatchOpenModal: (content: Object, id: string) => void, // dispatches action to add a modal to Store
|}

type MappedProps = {|
  userId: string, // user's uuid
  selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>, // user selected account id
  realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>, // current estate id
  realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>, // current estate name
  currentEstimate: $PropertyType<TVDApplicationStore, 'calculation'>, // estimate id that the user is in
|}

type ReceivedProps = {|
  app: string, // app name
  calculations: Array<TVDCalculation>, // list of calculations
  userId: string, // user's id from Store
  closeFn: Function, // function to fire when an estimate is selected or other actions are taken
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type State = {|
  locks: Array<TVDLock>, // lock objects that are considered locked by API
|}

export class CalculationsList extends Component<Props, State> {
  static defaultProps = {
    closeFn: () => {}
  }
  state = {
    locks: []
  }
  componentDidUpdate(prevProps: Object) {
    const { locks } = this.state
    const { calculations } = this.props

    if (locks.length === 0
      && calculations
      && prevProps.calculations
      && prevProps.calculations.length === 0
      && calculations.length > 0) {
      this.getLockedEstimates()
    }
  }

  getModalContent = (estimateId: string, name: string, estimateDescription: string) => {
    const { app, t } = this.props
    return {
      title: 'listItemDialog._DELETE_',
      saveButtonText: 'buttons._DELETE_',
      message: `${t('calculationList._DELETE_CALCULATION_CONFIRMATION_DIALOG_MESSAGE_')} ${estimateDescription}?`,
      onSave: () => deleteEstimatesWithEstimateIdRequest({ path: { estimateId } }, { estimateId, app }),
    }
  }

  columns(): Array<Object> {
    const { t, app } = this.props

    const columns = [
      { key: 'description', localizedName: t('calculationList._NAME_') },
      { key: 'assessmentDate', localizedName: t('calculationList._INSPECTION_DATE_') },
      { key: 'indexNumberOnAssessmentDate', localizedName: t('calculationList._HAAHTELA_INDEX_') },
      { key: 'createdBy', localizedName: t('calculationList._CREATOR_') },
      { key: 'createdDate', localizedName: t('calculationList._CREATED_') },
      { key: 'modifiedDate', localizedName: t('calculationList._EDITED_') },
    ]
    const wopColumns = columns.filter((column: Object) => column.key !== 'assessmentDate' && column.key !== 'indexNumberOnAssessmentDate')
    const locanColumns = columns.filter((column: Object) => column.key !== 'indexNumberOnAssessmentDate')

    switch (app) {
      case WOP:
        return wopColumns
      case LOCAN:
        return locanColumns
      default:
        return columns
    }
  }

  rows(): Array<Array<any>> {
    const { calculations } = this.props

    // use column key to detect data type and format accordingly
    const formatColumnData = (calculationCellValue: any, columnKey: string): string | number => {
      switch (columnKey) {
        case 'modifiedDate':
        case 'createdDate':
        case 'assessmentDate': return formattedDate(calculationCellValue)
        case 'indexNumberOnAssessmentDate': return round(calculationCellValue, 1)
        default: return calculationCellValue
      }
    }

    //  for each calculation, look for each available column in the order of the
    //  this.columns array and use the column.key to format the value accordingly
    return calculations
      .map((calculation: TVDCalculation) =>
        this.columns().map((column: Object) =>
          formatColumnData(calculation[column.key], column.key)))
  }

  // eslint-disable-next-line
  estimateLockFn(payload?: Object, successCb?: Function, errorCb?: Function, options?: Object): Function {
    const { app } = this.props
    switch (app.toUpperCase()) {
      case SPACES: return getEstimateLockWithEstimateIdRequestSpaces({}, successCb, null, options)
      case ELEMENTS: return getEstimateLockWithEstimateIdRequestElements({}, successCb, null, options)
      case WOP: return getEstimateLockWithEstimateIdRequestWop({}, successCb, null, options)
      case LOCAN: return getEstimateLockWithEstimateIdRequestLocan({}, successCb, null, options)
      default: {
        console.error(`Can't use ${app} to determine correct requests for locks`)
        return null
      }
    }
  }

  getLockedEstimates() {
    if (this.estimateLockFn) {
      runAllGARs(this.props.calculations.map(({ id }: TVDCalculation) =>
        this.estimateLockFn({}, (lock: TVDLock) => {
          this.setState({ locks: [...this.state.locks, lock] })
        }, null, { estimateId: id })))
    }
  }

  getConfirmDeleteEstimateModalContent(rowIndex: number, modalId: string): Object {
    const {
      t,
      calculations,
      dispatchCloseModal,
      app,
      userId
    } = this.props
    const {
      id: estimateId,
      description: estimateDescription,
      buildingId,
      estimateType
    } = calculations[rowIndex]
    return {
      disableCloseOnSave: true,
      title: 'listItemDialog._DELETE_',
      saveButtonText: 'buttons._DELETE_',
      message: `${t('calculationList._DELETE_CALCULATION_CONFIRMATION_DIALOG_MESSAGE_')} ${estimateDescription}?`,
      type: CONFIRMATION_MODAL,
      onClose: () => { deleteEstimateLockWithEstimateIdRequest({}, () => { dispatchCloseModal(modalId) }, null, { estimateId }) },
      onSave: () => {
        deleteEstimatesWithEstimateIdRequest({ path: { estimateId } }, { estimateId, app }, () => {
          getEstimatesRequest({ query: { estimateType, buildingId, permissionsSubject: userId } }, {}, () => {
            dispatchCloseModal(modalId)
          })
        }, { estimateId })
      },
    }
  }

  getContextMenuItems(rowIndex: number, hasLock: boolean): Array<Object> {
    const {
      t,
      calculations,
      dispatchCopyCalculation,
      dispatchOpenModal,
      currentEstimate
    } = this.props
    const { permissionsSubjectPermissions, id, primaryEstimate } = calculations[rowIndex]
    const userIsOwner = permissionsSubjectPermissions.includes(OWNER)
    const userIsManager = permissionsSubjectPermissions.includes(MANAGER)
    const isUserOwnerOrManager = userIsOwner || userIsManager
    const isCurrentEstimate = currentEstimate === id
    const isDeleteDisabled = hasLock || primaryEstimate || isCurrentEstimate

    const menuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('calculationList._COPY_'),
        testId: 'ContextMenuItem-copy',
        onClick: () => {
          const { estimateType, buildingId } = calculations[rowIndex]
          dispatchCopyCalculation(id, estimateType, buildingId)
        }
      },
      {
        tooltip: calculations[rowIndex].primaryEstimate && t('calculationList._WARNING_TOOLTIP_'),
        disabled: isDeleteDisabled,
        localizedName: t('calculationList._DELETE_'),
        testId: isDeleteDisabled ? 'ContextMenuItem-delete-disabled' : 'ContextMenuItem-delete', // different testId when hasLock is true so we can assert item is disabled in e2e lock-tests
        onClick: () => {
          const { id: estimateId } = calculations[rowIndex]
          const modalId = 'delete-calculation-modal'
          putEstimateLockWithEstimateIdRequest({ body: {} }, {}, () => {
            dispatchOpenModal(this.getConfirmDeleteEstimateModalContent(rowIndex, modalId), modalId)
          }, null, { estimateId })
        },
      },
      {
        localizedName: t(isUserOwnerOrManager ?
          'calculationList._INFORMATION_AND_USER_PERMISSIONS_' :
          'calculationList._INFORMATION_'),
        testId: hasLock ? 'ContextMenuItem-information-disabled' : 'ContextMenuItem-information', // different testId when hasLock is true so we can assert item is disabled in e2e lock-tests
        onClick: () => {
          const {
            estimateType,
            description,
            assessmentDate,
            indexNumberOnAssessmentDate,
            priceLevelChangeP,
            frozen
          } = calculations[rowIndex]
          const openCalculationInformationModal = () => {
            dispatchOpenModal({
              id,
              estimateType,
              estimateDescription: description,
              assessmentDate,
              indexNumberOnAssessmentDate,
              priceLevelChangeP,
              type: MODAL_TYPE_CALCULATION_INFORMATION,
              isUserOwnerOrManager,
              onClose: () => {
                deleteEstimateLockWithEstimateIdRequest({}, null, null, { estimateId: id })
                this.props.dispatchCloseModal(id)
              },
              title: 'calculationList._INFORMATION_AND_USER_PERMISSIONS_',
              containerType: 'dialog',
              hasLock,
              isFrozen: frozen
            }, id)
          }
          if (hasLock) {
            openCalculationInformationModal()
          } else if (!hasLock) {
            putEstimateLockWithEstimateIdRequest(
              { body: {} },
              {},
              () => { openCalculationInformationModal() },
              null,
              { estimateId: id }
            )
          }
        }
      }]
    return menuItems
  }

  getFirstCellHamburgerMenu(rowIndex: number, hasLock: boolean): React$Element<HamburgerMenu> {
    const { calculations } = this.props

    return <HamburgerMenu id={calculations[rowIndex].description} items={this.getContextMenuItems(rowIndex, hasLock)} />
  }

  openCalculation(id: string, description: string) {
    const {
      dispatchSetCalculation,
      dispatchSetEstimateName,
      selectedAccountId,
      history,
      app,
      realEstateId,
      realEstateName
    } = this.props
    if (id && description && selectedAccountId) {
      dispatchSetCalculation(id)
      dispatchSetEstimateName(description)

      const newHistory = getEstimateHistoryObj({
        app: app.toUpperCase(),
        accountId: selectedAccountId,
        realEstateId,
        estimateId: id,
        realEstateName
      })

      history.push(newHistory)
      this.props.closeFn()
    }
  }

  getFirstCellContent(content: any, rowIndex: number): React$Element<any> {
    const { calculations, classes } = this.props

    const calculation = calculations[rowIndex]
    const { id, description, frozen } = calculation
    const [matchingEstimate = {}] = this.state.locks.filter((lock: Object) => lock.resourceId === id && !lock.isExpired)
    const hasLock = !!(matchingEstimate.resourceId && (matchingEstimate.userId !== this.props.userId))
    return (
      <div className={classes.descriptionWrapper}>
        <DescriptionCell
          highlighted
          onClick={() => { this.openCalculation(id, description) }}
          text={content}
          marginLeft='32px' />
        { hasLock && <Icon data-testid={`${content}-lock`} className={classes.lockIcon}>lock</Icon> }
        { frozen && <Icon data-testid={`${content}-frozen-lock`} className={classes.lockIcon}>lock_outlined</Icon> }
        <div className={classes.hamburgerWrapper}>{ this.getFirstCellHamburgerMenu(rowIndex, hasLock) }</div>
      </div>
    )
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.tableWrapper}>
        <SimpleDataTable
          testId='calculationList-table'
          headerCellInitialWidth={150}
          pusherWidth={500}
          columns={this.columns()}
          rows={this.rows()}
          wrappedCellContents={{
            index0: ({ content, rowIndex }: TVDWrappedCellCallbackParameters) => (
              this.getFirstCellContent(content, rowIndex)
            )
          }} />
      </div>
    )
  }
}

const mapStateToProps = ({ app, user }: TVDReduxStore): MappedProps => ({
  userId: user.claims.userId,
  selectedAccountId: app.selectedAccountId,
  realEstateId: app.realEstateId,
  realEstateName: app.realEstateName,
  currentEstimate: app.calculation
})

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchOpenModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
  dispatchSetCalculation: (id: string) => { dispatch(setCalculation(id)) },
  dispatchSetBuildingName: (buildingName: string) => { dispatch(setBuildingName(buildingName)) },
  dispatchSetEstimateName: (description: string) => { dispatch(setEstimateName(description)) },
  dispatchCopyCalculation: (id: string, appKey: string, buildingId: string) => { dispatch(copyCalculation(id, appKey, buildingId)) },
  dispatchCloseModal: (modalId: string) => { dispatch(closeModal(modalId)) }
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation('translations')
)(CalculationsList)
