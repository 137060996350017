// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component, type Node } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { includes, indexOf, last } from 'lodash'
import Icon from '@material-ui/core/Icon'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { colors, borderRadiuses, boxShadows, typographyClasses } from 'frontend-assets'
import Tooltip from '../../common/Tooltip/Tooltip'
import Content from '../../common/Content/Content'
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'
import ResultBarContainer from '../ResultBar/ResultBarContainer'
import FooterButtons from './FooterButtons/FooterButtons'
import Spinner from '../../common/Spinner/Spinner'
import { firstLevelWidgets } from '../../../constants/moduleConstants'
import {
  PRICEITEM,
  childWidgets,
  BUILDING_ELEMENTS_TASK,
  GROUPING_VIEW,
  CREATE_FROM_REGISTRY,
  CREATE_ACTIVITY,
  RENOVATION,
  SURFACE_PRICING,
  EQUIPMENT_PRICING,
  CALCULATION_PROPERTIES
} from '../../../constants/contentTypes'
import { getButtonsByWidgetType } from '../../../utils/footerButtonUtils'
import { getFooterTextByWidgetType } from '../../../utils/footerTextUtils'
import { setActiveWidgetAsTopmost } from '../../../actions/app'
import { closeWidget, clearWidgetData } from '../../../actions/widgets'
import {
  setXYComponentPreference,
  setSizeComponentPreference
} from '../../../actions/componentPreferences'

const { primary100, primary20, dark80 } = colors
const { borderRadiusLarge } = borderRadiuses
const { boxShadowFloatingWindow } = boxShadows
const { h4 } = typographyClasses

// root z-index is 1101 so it doesnt render behind header
// which z-index is 1100
const BORDER_RADIUS = borderRadiusLarge
const styles = ({ palette, typography }: Object): Object => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: boxShadowFloatingWindow,
    borderRadius: BORDER_RADIUS,
    minHeight: '185px',
    minWidth: '220px'
  },
  header: {
    minHeight: '48px',
    maxHeight: '48px',
    color: palette.white,
    backgroundColor: palette.primary80,
    paddingRight: '5px',
    paddingLeft: '24px'
  },
  firstLevelHeader: {
    backgroundColor: primary100
  },
  secondLevelHeader: {
    backgroundColor: primary20
  },
  titleWrapper: {
    maxWidth: 'calc(100% - 40px)',
  },
  title: {
    ...h4,
    fontSize: 18,
    cursor: 'default',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondLevelTitle: {
    color: dark80
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS
  },
  headerIcon: {
    display: 'flex',
    flexBasis: 'auto',
    cursor: 'pointer',
    alignSelf: 'center',
    margin: '0 0 0 10px',
  },
  secondLevelHeaderIcon: {
    color: dark80
  },
  titleIcon: {
    margin: '-2px 11px 0 -2px'
  },
  resizeHandle: {
    position: 'absolute',
    left: '1px',
    bottom: 0,
    zIndex: '9999',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    cursor: 'sw-resize',
  },
  resizeIcon: {
    marginLeft: '6px',
    marginTop: '2px',
    fontSize: '16px',
    color: palette.silver
  },
  content: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
  },
  footerActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  buttons: {
    marginTop: '16px',
    marginBottom: '24px',
    marginRight: '32px',
    display: 'flex',
    alignSelf: 'flex-end',
    flexShrink: 0
  },
  footerText: {
    display: 'flex',
    font: `12px ${typography.fontFamilyBase}`,
    color: palette.nevada,
    paddingLeft: '30px',
    marginBottom: '25px',
    alignSelf: 'flex-end',
    whiteSpace: 'pre-wrap', // to wrap text on line breaks
  },
  noButtons: {
    height: 16
  }
})

type State = {
  position: Object,
  relativeX: number,
  relativeY: number,
  width: number,
  height: number
}

type HOCProps = {|
  t: (string) => string, // translation function
  classes: Object, // classes-object created by withstyles function
|}

type MappedProps = {|
  application: string, // current application
  activeCalculation?: boolean, // flag that indicates if calculation is running
  resultBar?: Object, // resultbar data
  widgetLocation: Object, // Initial location of the widget
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate // if current estimate has a lock that is dedicated to current user
  widgetOrder: number, // widger rendering order
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  description?: string, // widget's descriptino from Store
  widgetId?: string, // id of the widget in the Store
  widgetLocation?: {| top: number, right: number |}, // locationMiddleware provided location of the widget
  xy?: [number, number], // component preferences widget position from Redux Store
  size?: { width: number, height: number } // component preferences widget size from Redux Store
|}

export type ClearableWidgetIds = {
  resourceId?: string, // id of an resource
  propertiesStoreId?: string, // id as a key used to store properties to Store
  resourceListId?: string, // list id for the content widget renders
  activityStructureId?: string, // activity structure id to store data to Store
}

type DispatchProps = {|
  dispatchClearWidgetData: (ids: ClearableWidgetIds) => void, // function to clear widget content data from store on unmount
  dispatchCloseWidget: (string) => void, // function to close widget
  dispatchSetActiveWidgetAsTopmost: () => void, // set the current widget on top of rest of the widgets
  dispatchSetXYComponentPreferences: (xy: [number, number]) => void, // stores widget position to Redux Store in component preferences
  dispatchSetSizeComponentPreference: (size: {width: number, height: number}) => void // stores widget size to Redux Store in component preferences
|}

type ReceivedProps = {|
  ...TVDOpenContentWidgetArguments,
  modified?: boolean, // if the widget is marked as modified
  widgetPositionFixed?: boolean, // indicates if widget cannot be moved
  disableResize?: boolean, // indicates that widget cannot be resized
  widgetLocation?: {| top: number, right: number |}, // locationMiddleware provided location of the widget
  widgetType: string, // type of the opened widget
  widgetId: string, // ID of the widget
  resourceId?: string, // legacy id key
  parentResourceId: string, // resourceId of the parent widget where the current instance was opened from
  resourceListId: string, // list id for the content widget renders
  contenProps?: Object, // optional props that are passed to the content the widget renders
  description?: string, // description prop that overwrites the one from Store
  hideHeaderButtons?: boolean, // indicates if widget header has no close button
  preferXYComponentPreferences?: boolean // indicates if widget should prefer xy component preferences over widgetLocation
|}

export type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

export class Widget extends Component<Props, State> {
  static defaultProps = {
    getData: null,
    callBacks: null,
    parentId: null,
    contentProps: {},
    activeCalculation: false,
    isEstimateLockedToCurrentUser: false,
    modified: false,
    hideHeaderButtons: false,
    widgetPositionFixed: false,
    disableResize: false
  }

  state = {
    position: {},
    relativeX: 0,
    relativeY: 0,
    width: this.getWidth(),
    height: this.getHeight()
  }

  componentDidMount() {
    const { widgetPreferSizeComponentPreferences, size } = this.props
    this.setState(() => ({
      position: this.getPosition(),
      ...((widgetPreferSizeComponentPreferences && !!size) ? { width: size.width, height: size.height } : {})
    }))
  }

  componentWillUnmount() {
    const {
      dispatchClearWidgetData,
      contentProps: {
        resourceId,
        propertiesStoreId,
        resourceListId,
        activityStructureId
      } = {},
      widgetId
    } = this.props
    if (
      resourceId ||
      widgetId ||
      propertiesStoreId ||
      resourceListId ||
      activityStructureId
    ) {
      dispatchClearWidgetData({
        resourceId: resourceId || widgetId,
        propertiesStoreId,
        resourceListId,
        activityStructureId
      })
    }
  }

  get headerButtons(): React$Element<typeof Icon> {
    const { dispatchCloseWidget, widgetType } = this.props
    return (
      <Icon
        data-testid={`${widgetType}-widget-close`}
        onClick={dispatchCloseWidget}>
        clear
      </Icon>
    )
  }

  get resizeHandle(): React$Element<any> {
    const { classes, widgetType } = this.props

    return (
      <div className={classes.resizeHandle} onMouseDown={this.resize} role='presentation' data-testid={`${widgetType}-resize`} />
    )
  }

  get header(): React$Element<typeof CardHeader> {
    const {
      classes,
      t,
      widgetType,
      widgetTitle,
      hideHeaderButtons,
      widgetPositionFixed
    } = this.props

    const title = widgetTitle ? `${t(`widgets._${widgetType}_`).toUpperCase()}: ${widgetTitle}` : t(`widgets._${widgetType}_`)
    const titleLevelClasses = !includes(firstLevelWidgets, widgetType) ? classes.secondLevelTitle : ''
    const headerLevelClasses = includes(firstLevelWidgets, widgetType) ? classes.firstLevelHeader : classes.secondLevelHeader
    const iconLevelClasses = !includes(firstLevelWidgets, widgetType) ? classes.secondLevelHeaderIcon : ''

    const cardTitle = (
      <Tooltip content={title} placement='top'>
        <div style={{ display: 'flex' }}>
          <div data-testid='widget-header-text' className={`${classes.title} ${titleLevelClasses}`}>{title}</div>
        </div>
      </Tooltip>
    )

    return (
      <CardHeader
        id={`${widgetType}-header`}
        data-testid={`${widgetType}-header`}
        titleTypographyProps={{ color: 'inherit', align: 'left' }}
        title={cardTitle}
        classes={{ action: `${classes.headerIcon} ${iconLevelClasses}`, content: classes.titleWrapper }}
        className={`${classes.header} ${headerLevelClasses}`}
        onMouseDown={!widgetPositionFixed ? this.drag : undefined}
        action={!hideHeaderButtons && this.headerButtons} />
    )
  }

  get content(): React$Element<typeof Content> {
    const {
      widgetType,
      widgetId,
      contentProps,
      isEstimateLockedToCurrentUser,
      isEstimateFrozen
    } = this.props
    return (
      <ErrorBoundary>
        <Content
          type={widgetType}
          widgetId={widgetId}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          isEstimateFrozen={isEstimateFrozen}
          {...contentProps} />
      </ErrorBoundary>
    )
  }

  get footerText(): React$Element<any> | null {
    const { t, classes, widgetType } = this.props
    const footerTextTranslateKey = getFooterTextByWidgetType(widgetType)

    return footerTextTranslateKey ? <p className={classes.footerText}>{t(footerTextTranslateKey)}</p> : <div className={classes.footerText} />
  }

  get buttons(): React$Element<FooterButtons> {
    const { classes } = this.props
    const buttons = getButtonsByWidgetType(this.props)

    return buttons.length ? <div className={classes.buttons}><FooterButtons items={buttons} /></div> : <div className={classes.noButtons} />
  }

  getPosition = (): Object => {
    const { xy, widgetPreferXYComponentPreferences, widgetLocation } = this.props
    if (widgetPreferXYComponentPreferences && xy) {
      const [right, top] = xy
      return { right, top }
    }
    const widgetPosition = widgetLocation || { right: 0, top: 181 }
    const childWidgetPosition = { right: 0, top: 234 }

    return childWidgets.includes(this.props.widgetType) ? childWidgetPosition : widgetPosition
  }

  drag = (e: MouseEvent) => {
    const { dispatchSetXYComponentPreferences } = this.props
    e.preventDefault()
    this.setState({
      relativeX: e.pageX,
      relativeY: e.pageY
    })
    const initialTop = this.state.position.top
    const initialRight = this.state.position.right


    const rightLimit = -(this.state.width - 50)
    const leftLimit = window.innerWidth - 50
    const topLimit = 0
    const bottomLimit = window.innerHeight - 50

    // Disable flow errors on document functions until better solution
    window.onmousemove = (mouseEvent: SyntheticMouseEvent<any>) => {
      const deltaX = mouseEvent.pageX - this.state.relativeX
      const deltaY = mouseEvent.pageY - this.state.relativeY

      const verticalChange = initialTop + deltaY
      const horizontalChange = initialRight - deltaX

      const newPosition = { top: verticalChange, right: horizontalChange }

      // check if widget is being dragged outside the viewport
      // and force value within viewport limits if true
      if (verticalChange < topLimit) newPosition.top = 0
      if (verticalChange > bottomLimit) newPosition.top = bottomLimit
      if (horizontalChange > leftLimit) newPosition.right = leftLimit
      if (horizontalChange < rightLimit) newPosition.right = rightLimit

      this.setState({ position: newPosition })
      dispatchSetXYComponentPreferences([newPosition.right, newPosition.top])
    }

    window.onmouseup = () => {
      window.onmousemove = undefined
      window.onmouseup = undefined
    }
  }

  resize = (e: MouseEvent) => {
    const { dispatchSetSizeComponentPreference } = this.props
    e.preventDefault()
    const initialHeight = this.state.height
    const initialWidth = this.state.width
    const mouseInitialPosition = { x: e.pageX, y: e.pageY }

    let minWidth = 220
    let minHeight = 185

    if (this.props.widgetType === CALCULATION_PROPERTIES) {
      minWidth = 605
      minHeight = 552
    }

    window.onmousemove = (mouseEvent: SyntheticMouseEvent<any>) => {
      const deltaX = mouseEvent.pageX - mouseInitialPosition.x
      const deltaY = mouseEvent.pageY - mouseInitialPosition.y
      let newHeight = initialHeight + deltaY
      let newWidth = initialWidth - deltaX

      // force minHeight and minWidth if widget is resized below those values
      // so that widget viewport checks don't break in "drag" - method
      if (newWidth < minWidth) newWidth = minWidth
      if (newHeight < minHeight) newHeight = minHeight

      this.setState({ width: newWidth, height: newHeight })
      // todo dispatch new width height to store
      dispatchSetSizeComponentPreference({ width: newWidth, height: newHeight })
    }
    window.onmouseup = () => {
      window.onmousemove = undefined
      window.onmouseup = undefined
    }
  }

  getWidth(): number {
    const windowWidth = window.innerWidth
    const parentMaxWidth = 962
    const { widgetType: wT } = this.props

    let parentMinWidth
    switch (wT) {
      case CREATE_FROM_REGISTRY:
      case CREATE_ACTIVITY:
        parentMinWidth = 1210
        break
      case CALCULATION_PROPERTIES:
        parentMinWidth = 605
        break
      default:
        parentMinWidth = 398
        break
    }

    if (wT === GROUPING_VIEW || wT === RENOVATION) parentMinWidth = windowWidth
    let childMaxWidth = 560
    let childMinWidth = 486
    if (wT === SURFACE_PRICING || wT === EQUIPMENT_PRICING) {
      childMaxWidth = parentMaxWidth
      childMinWidth = parentMaxWidth / 1.25
    }

    let parentWidth = windowWidth / 2
    if (parentWidth > parentMaxWidth) parentWidth = parentMaxWidth
    if (parentWidth < parentMinWidth) parentWidth = parentMinWidth

    let childWidth = parentWidth / 2
    if (childWidth > childMaxWidth) childWidth = childMaxWidth
    if (childWidth < childMinWidth) childWidth = childMinWidth

    return childWidgets.includes(this.props.widgetType) ? childWidth : parentWidth
  }

  getHeight(): number {
    const windowHeight = window.innerHeight
    const topMargin = 181
    const bottomMargin = 97
    const widgetHeight = windowHeight - topMargin - bottomMargin

    const childHeight = widgetHeight - 8 - 58

    const { widgetType: wT } = this.props
    if (wT === CALCULATION_PROPERTIES) {
      return Math.max(widgetHeight, 552)
    }

    return childWidgets.includes(this.props.widgetType) ? childHeight : widgetHeight
  }

  footer(): React$Element<'div'> | null {
    const {
      classes,
      widgetType,
      contentProps,
      widgetResultBarStoreSource,
      widgetResultBarFormatOptions,
    } = this.props

    const widgetsWithBuiltInFooter = [BUILDING_ELEMENTS_TASK, GROUPING_VIEW]
    if (widgetsWithBuiltInFooter.includes(widgetType)) return null

    return (
      <div className={classes.footer}>
        <div className={classes.footerActions}>
          {this.footerText}
          {this.buttons}
        </div>
        <ResultBarContainer
          contentProps={contentProps}
          widgetResultBarStoreSource={widgetResultBarStoreSource}
          widgetType={widgetType}
          resultBarKey={widgetType}
          resultBarFormatOptions={widgetResultBarFormatOptions} />
      </div>
    )
  }

  render(): Node {
    const {
      classes,
      widgetType,
      activeCalculation,
      widgetOrder,
      widgetId,
      disableResize
    } = this.props
    const { position, width, height } = this.state

    const rootStyle = {
      top: position.top,
      right: position.right,
      width,
      height,
      zIndex: 1220 + indexOf(widgetOrder, widgetId)
    }

    const handleClick = () => {
      if (last(widgetOrder) !== widgetId) this.props.dispatchSetActiveWidgetAsTopmost()
    }

    if (!widgetType && !widgetId) return null

    return (
      <div
        className={classes.root}
        style={rootStyle}
        data-testid={`${widgetType}-widget`}
        onMouseDown={handleClick}
        role='presentation'>
        { activeCalculation && <Spinner /> }
        <Card raised elevation={0} className={classes.card}>
          {this.header}
          <ErrorBoundary>
            <div className={classes.content}>
              { this.content}
            </div>
            { this.footer() }
            {
              !disableResize &&
              <div className={classes.resizeHandle} role='presentation' data-testid='resizer' >
                <Icon className={classes.resizeIcon}>call_received</Icon>
              </div>
            }
            {!disableResize && this.resizeHandle}
          </ErrorBoundary>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state: Object, props: ReceivedProps): MappedProps {
  const widgetId = props.resourceId || props.widgetId
  const componentPreferences = state.componentPreferences[props.widgetType.toLowerCase()] || {}
  const { xy, size } = componentPreferences

  if (props.widgetType === PRICEITEM && !props.description && props.parentResourceId !== undefined) {
    const { resourceId, parentResourceId, resourceListId } = props

    const {
      app: {
        application,
        activeCalculation,
        widgetOrder,
        isEstimateLockedToCurrentUser,
        isEstimateFrozen
      },
      list: {
        [resourceListId]: {
          assemblyElements: {
            [parentResourceId]: {
              [resourceId]: {
                Description: description
              },
            },
          },
        }
      }
    } = state

    return {
      application,
      activeCalculation,
      description,
      widgetOrder,
      isEstimateLockedToCurrentUser,
      isEstimateFrozen,
      xy,
      size
    }
  }
  const {
    widgets: { [props.resourceId ? props.resourceId : props.widgetId]: { widgetLocation } = {} },
    app: {
      application,
      activeCalculation,
      isEstimateLockedToCurrentUser,
      widgetOrder,
      isEstimateFrozen
    },
  } = state

  return {
    application,
    activeCalculation,
    widgetLocation: props.widgetLocation || widgetLocation,
    widgetOrder,
    widgetId,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    xy,
    size
  }
}

function mapDispatchToProps(dispatch: Function, {
  resourceId,
  widgetId,
  widgetType
}: Object): DispatchProps {
  return {
    dispatchCloseWidget: () => { dispatch(closeWidget(resourceId || widgetId)) },
    dispatchSetXYComponentPreferences: (xy: [number, number]) => {
      dispatch(setXYComponentPreference(widgetType, xy))
    },
    dispatchSetSizeComponentPreference: (size: { width: number, height: number }) => {
      dispatch(setSizeComponentPreference(widgetType, size))
    },
    dispatchSetActiveWidgetAsTopmost: () => { dispatch(setActiveWidgetAsTopmost(resourceId || widgetId)) },
    dispatchClearWidgetData: (ids: ClearableWidgetIds) => {
      dispatch(clearWidgetData(ids))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(withStyles(styles)(Widget)))
