// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createGenerateClassName, StylesProvider } from '@material-ui/core/styles'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { ConnectedRouter } from 'connected-react-router'
import slugify from 'slugify'
import 'moment/locale/fi'
import 'url-search-params-polyfill'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Axios from 'axios'
import { store, history } from './store'

import { unregister } from './registerServiceWorker'
import theme from './styles/theme'
import i18n from './i18n'
import AccountManager from './components/scenes/LandingPage/AccountManager'
import HaahtelaAdmin from './components/scenes/LandingPage/HaahtelaAdmin'
import AdminPage from './components/scenes/LandingPage/AdminPage'
import Auth from './components/scenes/Auth/Auth'
import Logout from './components/scenes/Logout/Logout'
import PrivateRoute from './components/common/PrivateRoute/PrivateRoute'
import {
  FEATURE_ACCOUNT_MANAGEMENT_MODULE,
  FEATURE_ADMIN_MODULE,
  FEATURE_SCENARIO_RENOVATION_USE,
  FEATURE_NEW_PROCUREMENT_ELEMENTS
} from './constants/features'
import ErrorScene from './components/scenes/ErrorScene/ErrorScene'
import TermsOfService from './components/scenes/TermsOfService/TermsOfService'
import AppView from './ProjectManagement/features/AppView/AppView'
import {
  summaryUrl,
  scenarioNewConstructionUrl,
  renovationProgramUrl,
  profitabilityUrl,
  spacesUrl,
  elementsUrl,
  wopUrl,
  procurementElementsUrl,
  procurementElementsNewUrl,
  locanUrl,
  usageAndMaintenanceUrl,
  scenarioRepairConstructionUrl
} from './constants/moduleConstants'
import { storeMFEDetails } from './actions/app'
import Redirect from './components/scenes/Redirect/Redirect'
import { getIsFeatureEnabledInSet } from './utils/features'

// initializing slugify for the entire app so all slugify calls share same configurations
slugify.extend({
  '/': '_',
  '%': '_'
})

Axios.get('/TVDMFEDetails').then(({ data }: Object) => {
  store.dispatch(storeMFEDetails(data))
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const generateClassName = createGenerateClassName({
  productionPrefix: 'front-end',
  seed: 'front-end'
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n} >
            <ConnectedRouter history={history}>
              <Switch>
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/account-management/auth' component={Auth} />
                <Route exact path='/haahtela-admin/auth' component={Auth} />
                <Route exact path='/auth' component={Auth} />
                <Route exact path='/kirjaudu' component={Redirect} />
                <PrivateRoute exact path='/' component={AppView} />
                <PrivateRoute featureName={FEATURE_ADMIN_MODULE} exact path='/admin/:adminId' component={AdminPage} />
                <PrivateRoute
                  featureName={FEATURE_ACCOUNT_MANAGEMENT_MODULE}
                  exact
                  path='/account-management'
                  component={AccountManager} />
                <PrivateRoute
                  exact
                  path='/haahtela-admin'
                  component={HaahtelaAdmin} />
                <PrivateRoute path={renovationProgramUrl} component={AppView} />
                <PrivateRoute exact path='/terms-of-service' component={TermsOfService} />
                <PrivateRoute exact path={summaryUrl} component={AppView} />

                <PrivateRoute featureName={FEATURE_SCENARIO_RENOVATION_USE} path={scenarioRepairConstructionUrl} component={AppView} />
                <PrivateRoute path={scenarioNewConstructionUrl} component={AppView} />
                <PrivateRoute path={profitabilityUrl} component={AppView} />
                <PrivateRoute path={spacesUrl} component={AppView} />
                <PrivateRoute path={elementsUrl} component={AppView} />
                <PrivateRoute path={wopUrl} component={AppView} />
                <PrivateRoute path={procurementElementsUrl} component={AppView} />
                {getIsFeatureEnabledInSet(FEATURE_NEW_PROCUREMENT_ELEMENTS) && (
                  <PrivateRoute path={procurementElementsNewUrl} component={AppView} />
                )}
                <PrivateRoute path={locanUrl} component={AppView} />
                <PrivateRoute path={usageAndMaintenanceUrl} component={AppView} />
                <Route path='*' component={ErrorScene} />
              </Switch>
            </ConnectedRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </I18nextProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  </QueryClientProvider>
  ,
  window.document.getElementById('root'),
)
unregister()

