// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// LIBRARIES
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'

import TextButton, { type TextButtonProps } from '../../common/TextButton/TextButton'
import Menu from '../../common/menus/Menu/Menu'
import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'

import { SPACES, ELEMENTS, WOP, RENOVATION_SITE_TAB } from '../../../constants/moduleConstants'
import { romanize } from '../../../utils/commonUtils'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import {
  FEATURE_BUILDING_ELEMENTS_SCHEDULE_WIDGET_MFE,
  FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE
} from '../../../constants/features'
import {
  viewMode,
  USAGEMAINTENANCE
} from '../../../constants/viewModeConstants'
import {
  CALCULATION_PROPERTIES,
  BUILDING_ELEMENTS_TASK,
  BUILDING_ELEMENTS_SCHEDULE,
  RENOVATION,
  WOP_SPACE_SCHEDULE,
  WOP_GROUPING_SPACESCHEDULE,
  WOP_GROUPING_GROUPINGSCHEDULE,
  CARBON_FOOTPRINT,
  SHOW_RESULTS
} from '../../../constants/contentTypes'
import {
  actionSpaces,
  otherSpaces,
  targetProperties,
  showResults,
  showBuildingElements,
  createElements,
  elementTask,
  renovation,
  generalFeatures,
  generalFeaturesWOP,
  type WidgetKeys,
  CREATE_ACTIVITY,
  GROUPING_VIEW,
  INSPECT_RESULT,
  GENERAL_FEATURES,
  GENERAL_FEATURES_WOP,
  IMPORT_FROM_WOP_CALCULATION,
  IMPORT_FROM_ANOTHER_ESTIMATE
} from '../../../constants/widgetKeys'
import { openContentWidget } from '../../../actions/widgets'
import { openReportModal } from '../../../actions/modals'
import BuildingElementsTasksWidgetMFEContainer from '../BuildingElementsTasksWidgetMFEContainer/BuildingElementsTasksWidgetMFEContainer'
import ImportFromAnotherEstimateWidgetMFEContainer from '../ImportFromAnotherEstimateWidgetMFEContainer/ImportFromAnotherEstimateWidgetMFEContainer'
import BuildingElementsScheduleWidgetMFEContainer from '../BuildingElementsScheduleWidgetMFEContainer/BuildingElementsScheduleWidgetMFEContainer'
import EstimateNotesMFEContainer from '../EstimateNotesMFEContainer/EstimateNotesMFEContainer'
// $FlowFixMe
import { ReactComponent as NotepadIcon } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Notepad.svg'
import SiteEquipmentProductAssemblyMFEContainer from '../SiteEquipmentProductAssemblyMFEContainer/SiteEquipmentProductAssemblyMFEContainer'
import SpacesLifecycleCO2WidgetMFEContainer from '../SpacesLifecycleCO2WidgetMFEContainer/SpacesLifecycleCO2WidgetMFEContainer'

const styles = {
  toolsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    rowGap: '4px',
    columnGap: '8px',
  },
}

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // classes-object generated by withStyles function
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
|}

type MappedProps = {|
  application: string, // current application
  spacesListId: string, // the unique id for the main Spaces list component
  activityStructureId: string, // id of activityStructure
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  disabled: $PropertyType<TVDApplicationStore, 'activeEdit'>, // if save pattern is active
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  licenseType: $PropertyType<TVDApplicationStore, 'licenseType'> // type of TVD License, project, legacyProject or scenario
|}


type DispatchProps = {|
  dispatchOpenContentWidgetStrict: Function, // Open widget function with restricted arguments
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchOpenReportModal: (key: string) => void, // Opens ReportModal
|}

type Props = {
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
}

type State = {
  isTaskWidgetOpen: boolean,
  isImportFromSpacesEstimateWidgetOpen: boolean,
  isBuildingElementsScheduleWidgetOpen: boolean,
  isEstimateNotesWidgetOpen: boolean,
  isSiteEquipmentProductAssemblyWidgetOpen: boolean,
  selectedSiteEquipmentAssemblyId: string | null,
  isSpacesLifecycleCO2WidgetOpen: boolean, // if the SpacesLifecycleCO2Widget is open
}

export class ToolsContainer extends Component<Props, State> {
  state = {
    isTaskWidgetOpen: false,
    isImportFromSpacesEstimateWidgetOpen: false,
    isBuildingElementsScheduleWidgetOpen: false,
    isEstimateNotesWidgetOpen: false,
    isSiteEquipmentProductAssemblyWidgetOpen: false,
    selectedSiteEquipmentAssemblyId: null,
    isSpacesLifecycleCO2WidgetOpen: false
  }

  componentDidUpdate = (prevProps: Props) => {
    const { spacesEstimateType } = this.props
    if (prevProps.spacesEstimateType !== spacesEstimateType) {
      if (spacesEstimateType === USAGEMAINTENANCE) {
        this.setState({ isSpacesLifecycleCO2WidgetOpen: false })
      }
    }
  }

  setIsTaskWidgetOpen = (isTaskWidgetOpen: boolean): void => {
    this.setState({
      isTaskWidgetOpen
    })
  }

  setIsImportFromSpacesEstimateWidgetOpen = (isImportFromSpacesEstimateWidgetOpen: boolean): void => {
    this.setState({
      isImportFromSpacesEstimateWidgetOpen
    })
  }

  setIsBuildingElementsScheduleWidgetOpen = (isBuildingElementsScheduleWidgetOpen: boolean): void => {
    this.setState({
      isBuildingElementsScheduleWidgetOpen
    })
  }

  setIsEstimateNotesWidgetOpen = (isEstimateNotesWidgetOpen: boolean): void => {
    this.setState({
      isEstimateNotesWidgetOpen
    })
  }

  setIsSiteEquipmentProductAssemblyWidgetOpen = (isSiteEquipmentProductAssemblyWidgetOpen: boolean): void => {
    this.setState({
      isSiteEquipmentProductAssemblyWidgetOpen
    })
  }

  setSelectedSiteEquipmentAssemblyId = (selectedSiteEquipmentAssemblyId: string): void => {
    this.setState({
      selectedSiteEquipmentAssemblyId
    })
  }

  /**
   * Builds a single menu with a TextButton (variant: toolButton) as the root component
   * @param {WidgetKeys} keys - Keys for the widgets, used for the handleOpenWidget method to open a widget
   * @param {string} text - the text that goes in the TextButton
   * @param {boolean} hasTitleItem - whether the menu should have a divider under the first item
   * @param {boolean} romanized - will put roman numerals in front of the item label
   */
  buildMenu = (
    keys: WidgetKeys,
    text: string,
    number?: number,
    hasTitleItem: boolean = false,
    romanized: boolean = false,
    customOnClickHandlers?: { [key: string]: () => void }
  ) => {
    const {
      t,
      disabled,
      features,
      dispatchOpenReportModal,
      application,
      isEstimateFrozen,
      licenseType,
    } = this.props
    const isIconButton = text === GENERAL_FEATURES || text === GENERAL_FEATURES_WOP

    /*
     * Parses roman numerals to all the list items except the first.
     * @param (number) index - the index number of the item, used to get the numeral
     * @param (string) text - the keyword passed to the translation function
    */
    const parseRomanizedTitle = (index: number, label: string) => {
      if (index !== 0) {
        return `${romanize(index)} ${t(`widgets._${label}_`)}`
      }
      return `${t(`widgets._${label}_`)} (I-IV)`
    }

    type FeatureMenuItemProps = {|
      ...TVDMenuItem,
      onFeatureDisabled: Function, // returns props used when feature is disabled. This fn is filtered out from the props via getEnabledFeatures
    |}

    const items: Array<TVDMenuItem> = features.getEnabledFeatures(map(
      keys.widgets,
      (widget: string, index: number): FeatureMenuItemProps => {
        const handleClick = () => {
          if (isIconButton) {
            dispatchOpenReportModal(application)
          } else if (customOnClickHandlers?.[widget]) {
            customOnClickHandlers?.[widget]()
          } else {
            this.handleOpenWidget(widget)
          }
        }

        const props = {
          testId: widget,
          value: widget,
          localizedName: romanized ? parseRomanizedTitle(index, widget) : t(`widgets._${widget}_`),
          onClick: handleClick
        }
        return { ...props, onFeatureDisabled: () => ({ ...props, disabled: true }) }
      }
    ), 'value')
      // filter menuItems based on licenseType
      .filter((item: TVDMenuItem) => (licenseType === 'scenario' ? item.value !== IMPORT_FROM_WOP_CALCULATION : item))

    if (hasTitleItem) items.splice(1, 0, { divider: true })

    const isDisabledDueFreezing = keys.groupLabel === targetProperties.groupLabel || keys.groupLabel === SHOW_RESULTS ? false : !!isEstimateFrozen

    const buttonProps: TextButtonProps = {
      text,
      variant: 'toolButton',
      iconRight: 'arrow_drop_down',
      disabled: features.getIsFeatureDisabled(keys.groupLabel) || disabled || isDisabledDueFreezing,
      id: keys.groupLabel,
      number
    }
    const iconButtonProps: TextButtonProps = {
      ...buttonProps,
      disabled: false,
      text: '',
      variant: 'icon',
      iconRight: 'more_horiz'
    }

    const props = isIconButton ? iconButtonProps : buttonProps

    return (
      <Menu
        items={items}
        data-testid='ToolMenu'
        key={keys.groupLabel}
        disablePortal={false}
        toolMenu>
        <TextButton {...props} />
      </Menu>
    )
  }

  handleOpenWidget = (key: string) => {
    const { dispatchOpenContentWidget, dispatchOpenContentWidgetStrict } = this.props
    switch (key) {
      case IMPORT_FROM_ANOTHER_ESTIMATE: {
        this.setIsImportFromSpacesEstimateWidgetOpen(true)
        break
      }
      case BUILDING_ELEMENTS_TASK: {
        if (this.shouldUseBuildingTasksWidgetMFE()) {
          this.setIsTaskWidgetOpen(true)
        } else {
          dispatchOpenContentWidget({
            widgetId: BUILDING_ELEMENTS_TASK,
            widgetType: BUILDING_ELEMENTS_TASK,
            widgetEnableAllWidgetsOnClose: true,
            contentProps: {
              selectedTab: 'properties',
              propertiesStoreId: BUILDING_ELEMENTS_TASK,
              listStoreId: BUILDING_ELEMENTS_TASK,
            },
          })
        }
        break
      }
      case CALCULATION_PROPERTIES: {
        dispatchOpenContentWidget({
          widgetId: CALCULATION_PROPERTIES,
          widgetType: CALCULATION_PROPERTIES,
          contentProps: {
            propertiesStoreId: `${CALCULATION_PROPERTIES}-PROPERTIES`,
            listStoreId: `${CALCULATION_PROPERTIES}-EQUIPMENTS`,
            updateTechnicalBuildingServices: false,
            openSiteEquipmentProductAssemblyWidget: (id: string) => {
              this.setSelectedSiteEquipmentAssemblyId(id)
              this.setIsSiteEquipmentProductAssemblyWidgetOpen(true)
            },
            handleCloseProductAssemblyWidget: () => {
              this.setIsSiteEquipmentProductAssemblyWidgetOpen(false)
            }
          },
        })
        break
      }
      case BUILDING_ELEMENTS_SCHEDULE: {
        dispatchOpenContentWidget({
          widgetId: BUILDING_ELEMENTS_SCHEDULE,
          widgetType: BUILDING_ELEMENTS_SCHEDULE,
          contentProps: {
            listStoreId: BUILDING_ELEMENTS_SCHEDULE,
          },
        })
        break
      }
      case RENOVATION: {
        dispatchOpenContentWidget({
          widgetId: RENOVATION,
          widgetType: RENOVATION,
          contentProps: {
            selectedTab: RENOVATION_SITE_TAB,
          }
        })
        break
      }
      case WOP_SPACE_SCHEDULE: {
        const { activityStructureId } = this.props
        dispatchOpenContentWidget({
          widgetId: WOP_SPACE_SCHEDULE,
          widgetType: WOP_SPACE_SCHEDULE,
          contentProps: {
            listStoreId: WOP_SPACE_SCHEDULE,
            activityStructureId,
            watchListStoreIds: [WOP_GROUPING_GROUPINGSCHEDULE, activityStructureId]
          }
        })
        break
      }
      case GROUPING_VIEW: {
        dispatchOpenContentWidget({
          widgetId: GROUPING_VIEW,
          widgetType: GROUPING_VIEW,
          contentProps: {
            groupingListStoreId: WOP_GROUPING_GROUPINGSCHEDULE,
            listStoreId: WOP_GROUPING_SPACESCHEDULE
          }
        })
        break
      }
      default:
        dispatchOpenContentWidgetStrict(key, '', key, key)
        break
    }
  }

  shouldUseBuildingTasksWidgetMFE = (): boolean =>
    getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE) &&
    // TODO: remove localStorage item check when API has been confirmed to work
    !!localStorage.getItem(FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE)

  toolMenus = (): Array<any> => {
    const {
      t,
      application,
      disabled,
      isEstimateFrozen,
      spacesEstimateType
    } = this.props

    switch (application) {
      case SPACES: {
        const renovationButtonProps: TextButtonProps = {
          text: `${t(`widgets._${renovation.widget}_`).toUpperCase()}`,
          onClick: () => { this.handleOpenWidget(renovation.widget) },
          variant: 'toolButton',
          id: renovation.widget,
          disabled: !!isEstimateFrozen,
          number: 4
        }
        const showBuildingElementsUsageMaintenanceButtonProps: TextButtonProps = {
          text: t('widgets._TASKS_'),
          onClick: () => { this.handleOpenWidget(showBuildingElements.widget) },
          variant: 'toolButton',
          disabled,
          id: showBuildingElements.widget,
          number: spacesEstimateType === viewMode.RENOVATION ? 5 : 4
        }

        const toolButtons = [
          this.buildMenu(
            actionSpaces,
            `${t(`widgets._${(actionSpaces.groupLabel)}_`)}`.toUpperCase(), 1
          ),
          this.buildMenu(otherSpaces, `${t(`widgets._${(otherSpaces.groupLabel)}_`)}`.toUpperCase(), 2, true, true),
          this.buildMenu(targetProperties, `${t(`widgets._${(targetProperties.groupLabel)}_`).toUpperCase()}`, 3),
          spacesEstimateType === USAGEMAINTENANCE ?
            <TextButton
              {...showBuildingElementsUsageMaintenanceButtonProps}
              key={FEATURE_BUILDING_ELEMENTS_SCHEDULE_WIDGET_MFE}
              onClick={() => this.setIsBuildingElementsScheduleWidgetOpen(true)}
              id={FEATURE_BUILDING_ELEMENTS_SCHEDULE_WIDGET_MFE} />
            : this.buildMenu(
              showResults,
              `${t(`widgets._${(showResults.groupLabel)}_`).toUpperCase()}`,
              spacesEstimateType === viewMode.RENOVATION ? 5 : 4,
              false,
              false,
              {
                [CARBON_FOOTPRINT]: (): void => {
                  this.setState({ isSpacesLifecycleCO2WidgetOpen: true })
                },
                [BUILDING_ELEMENTS_SCHEDULE]: (): void => {
                  this.setIsBuildingElementsScheduleWidgetOpen(true)
                }
              }
            ),
          <TextButton
            text=''
            onClick={() => this.setIsEstimateNotesWidgetOpen(true)}
            variant='icon'
            iconRight={
              <div style={{ marginTop: -2, marginLeft: -2 }}> {/* to force the svg not to crop */}
                <NotepadIcon />
              </div>
          }
            disabled={disabled}
            id='frontend-containers-estimate-notes'
            key='estimate-notes' />,
          this.buildMenu(generalFeatures, generalFeatures.groupLabel),
        ]

        // add renovation button as third to last item in toolButtons
        if (spacesEstimateType === viewMode.RENOVATION) {
          toolButtons.splice(
            -3,
            0, (
              <TextButton
                {...renovationButtonProps}
                disabled={!!isEstimateFrozen}
                key={renovation.widget} />
            )
          )
        }
        return toolButtons
      }

      case ELEMENTS: {
        const elementTaskButton: TextButtonProps = {
          text: `${t(`widgets._${elementTask.widget}_`)}`,
          onClick: () => {
            this.handleOpenWidget(elementTask.widget)
          },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: elementTask.widget,
          number: 2
        }

        return [
          this.buildMenu(createElements, `${t(`widgets._${(createElements.groupLabel)}_`)}`, 1),
          <TextButton {...elementTaskButton} key={elementTask.widget} disabled={!!isEstimateFrozen} />,
          this.buildMenu(generalFeatures, generalFeatures.groupLabel)
        ]
      }
      case WOP: {
        const createActivityButton: TextButtonProps = {
          text: `${t(`widgets._${CREATE_ACTIVITY}_`)}`,
          onClick: () => { this.handleOpenWidget(CREATE_ACTIVITY) },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: CREATE_ACTIVITY,
          number: 1
        }
        const groupingViewButton: TextButtonProps = {
          text: `${t(`widgets._${GROUPING_VIEW}_`)}`,
          onClick: () => { this.handleOpenWidget(GROUPING_VIEW) },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: GROUPING_VIEW,
          number: 2
        }
        const spaceSchedule: TextButtonProps = {
          text: `${t(`widgets._${INSPECT_RESULT}_`)}`,
          onClick: () => { this.handleOpenWidget(WOP_SPACE_SCHEDULE) },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: WOP_SPACE_SCHEDULE,
          number: 3
        }
        return [
          <TextButton key={CREATE_ACTIVITY} {...createActivityButton} />,
          <TextButton key={GROUPING_VIEW} {...groupingViewButton} />,
          <TextButton key={WOP_SPACE_SCHEDULE} {...spaceSchedule} />,
          this.buildMenu(generalFeaturesWOP, generalFeaturesWOP.groupLabel)
        ]
      }
      default:
        return []
    }
  }

  render(): React$Element<any> {
    const { classes } = this.props
    const { isSpacesLifecycleCO2WidgetOpen } = this.state

    return (
      <div className={classes.toolsContainer}>
        <>
          <ImportFromAnotherEstimateWidgetMFEContainer
            setIsWidgetOpen={(isOpen: boolean): void => {
            this.setIsImportFromSpacesEstimateWidgetOpen(isOpen)
            }}
            isWidgetOpen={this.state.isImportFromSpacesEstimateWidgetOpen} />
          {
          this.shouldUseBuildingTasksWidgetMFE() && (
            <BuildingElementsTasksWidgetMFEContainer
              setIsTasksWidgetOpen={(isOpen: boolean): void => {
                this.setIsTaskWidgetOpen(isOpen)
              }}
              isTasksWidgetOpen={this.state.isTaskWidgetOpen} />
          )
        }
          <EstimateNotesMFEContainer
            setIsWidgetOpen={(isOpen: boolean): void => {
            this.setIsEstimateNotesWidgetOpen(isOpen)
            }}
            isWidgetOpen={this.state.isEstimateNotesWidgetOpen} />
          <BuildingElementsScheduleWidgetMFEContainer
            setIsWidgetOpen={(isOpen: boolean): void => {
                this.setIsBuildingElementsScheduleWidgetOpen(isOpen)
              }}
            isWidgetOpen={this.state.isBuildingElementsScheduleWidgetOpen} />
          <SiteEquipmentProductAssemblyMFEContainer
            siteEquipmentAssemblyId={this.state.selectedSiteEquipmentAssemblyId}
            isWidgetOpen={this.state.isSiteEquipmentProductAssemblyWidgetOpen}
            setIsWidgetOpen={(isOpen: boolean): void => {
                  this.setIsSiteEquipmentProductAssemblyWidgetOpen(isOpen)
                }} />

          <SpacesLifecycleCO2WidgetMFEContainer
            isWidgetOpen={isSpacesLifecycleCO2WidgetOpen}
            closeWidget={(): void => {
              this.setState({ isSpacesLifecycleCO2WidgetOpen: false })
            }} />
        </>
        {this.toolMenus()}
      </div>
    )
  }
}

function mapStateToProps({ app }: TVDReduxStore): MappedProps {
  const {
    application,
    activeEdit,
    spacesListId,
    activityStructureId,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser,
    languageCode,
    spacesEstimateType,
    licenseType
  } = app

  return {
    application,
    disabled: activeEdit,
    spacesListId,
    activityStructureId,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser,
    languageCode,
    spacesEstimateType,
    licenseType
  }
}

function mapDispatchToProps(dispatch: Function): Object {
  return {
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
    dispatchOpenReportModal: (key: string) => { dispatch(openReportModal(key)) },
    dispatchOpenContentWidgetStrict: (widgetType: string, description: string, resourceId: string, resourceListId?: string) => {
      dispatch(openContentWidget({
        widgetType,
        widgetId: resourceId,
        contentProps: {
          description,
          resourceId,
          resourceListId
        }
      }))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
  FeaturesHOC
)(ToolsContainer)
