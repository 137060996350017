// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ActionBar from '../../common/components/ActionBar/ActionBar'
import IconButton from '../../common/components/IconButton/IconButton'
import Icon from '../../common/components/icons/Icon/Icon'
import {
  REAL_ESTATES,
  SPACES,
  ELEMENTS,
  WOP,
  LOCAN,
  PROFITABILITY,
  PROCUREMENT_ELEMENTS,
  PROCUREMENT_ELEMENTS_NEW,
  summaryUrl,
  RENOVATION_PROGRAM,
  USAGE_AND_MAINTENANCE
} from '../../../constants/moduleConstants'
import { FEATURE_NEW_PROCUREMENT_ELEMENTS } from '../../../constants/features'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { enableAllWidgets } from '../../../actions/widgets'
import { dispatch } from '../../../store'

import { getEstimateHistoryObj, createQueryParamsString } from '../../../utils/urlUtil'
// $FlowFixMe
import { ReactComponent as WOPSVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/WOP.svg'
// $FlowFixMe
import { ReactComponent as SpacesSVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/Spaces.svg'
// $FlowFixMe
import { ReactComponent as BuildingElementsSVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/Building_elements.svg'
// $FlowFixMe
import { ReactComponent as LocationSVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/Location.svg'
// $FlowFixMe
import { ReactComponent as ProfitabilitySVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/Profitability.svg'
// $FlowFixMe
import { ReactComponent as PropertySVG } from
  '../../../../node_modules/frontend-assets/static/assets/images/icons/Other Navigation icon property.svg'
// $FlowFixMe
import { ReactComponent as ProcurementElementsSVG }
  from '../../../../node_modules/frontend-assets/static/assets/images/modules/Procurement_elements.svg'
// $FlowFixMe
import { ReactComponent as RenovationSVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/Renovation.svg'
// $FlowFixMe
import { ReactComponent as MaintenanceSVG } from '../../../../node_modules/frontend-assets/static/assets/images/modules/Maintenance.svg'

const imgOptions = {
  WOP: WOPSVG,
  Tilat: SpacesSVG,
  Rakennusosat: BuildingElementsSVG,
  LOCAN: LocationSVG,
  PROFITABILITY: ProfitabilitySVG,
  PROPERTY: PropertySVG,
  PROCUREMENT_ELEMENTS: ProcurementElementsSVG,
  RENOVATION: RenovationSVG,
  USAGE_AND_MAINTENANCE: MaintenanceSVG
}

const procuementElementsNewApp = {
  imgSrc: imgOptions.PROCUREMENT_ELEMENTS,
  label: 'apps._PROCUREMENT_ELEMENTS_',
  testid: 'procurement-elements-new-app',
  name: PROCUREMENT_ELEMENTS_NEW,
  moduleId: 9
}

// moduleIds are based on the users api /enum/modules response
export const apps = [
  {
    imgSrc: imgOptions.PROPERTY,
    label: 'apps._REAL_ESTATE_',
    testid: 'real-estate-app',
    name: REAL_ESTATES
  },
  {
    imgSrc: imgOptions.Tilat,
    label: 'apps._SPACES_',
    testid: 'spaces-app',
    name: SPACES,
    moduleId: 1
  },
  {
    imgSrc: imgOptions.Rakennusosat,
    label: 'apps._ELEMENTS_',
    testid: 'building-elements-app',
    name: ELEMENTS,
    moduleId: 2
  },
  {
    imgSrc: imgOptions.WOP,
    label: 'apps._WOP_',
    testid: 'wop-app',
    name: WOP,
    moduleId: 3
  },
  {
    imgSrc: imgOptions.LOCAN,
    label: 'apps._LOCAN_',
    testid: 'locan-app',
    name: LOCAN,
    moduleId: 4
  },
  {
    imgSrc: imgOptions.PROFITABILITY,
    label: 'apps._PROFITABILITY_',
    testid: 'profitability-app',
    name: PROFITABILITY,
    moduleId: 5
  },
  {
    imgSrc: imgOptions.PROCUREMENT_ELEMENTS,
    label: 'apps._PROCUREMENT_ELEMENTS_',
    testid: 'procurement-elements-app',
    name: PROCUREMENT_ELEMENTS,
    moduleId: 6
  },
  {
    imgSrc: imgOptions.RENOVATION,
    label: 'apps._RENOVATION_',
    testid: 'renovation-program-app',
    name: RENOVATION_PROGRAM,
    moduleId: 7
  },
  {
    imgSrc: imgOptions.USAGE_AND_MAINTENANCE,
    label: 'apps._USAGE_AND_MAINTENANCE_',
    testid: 'usage-and-maintenance-app',
    name: USAGE_AND_MAINTENANCE,
    moduleId: 8
  }
]

const AppBar = (): React$Element<typeof ActionBar> => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    app: {
      application,
      selectedAccountId,
      primaryEstimates,
      realEstateId,
      realEstateName,
      moduleIds,
      activeEdit
    }
  } = useSelector((state: TVDReduxStore): TVDReduxStore => state)

  const isSelected = (appName: string): boolean => appName === application

  const procurementElementsNewIconButton = (
    <IconButton
      appName={procuementElementsNewApp.name}
      key={procuementElementsNewApp.testid}
      label={t(procuementElementsNewApp.label)}
      active={isSelected(procuementElementsNewApp.name)}
      onClick={() => {
        const primaryEstimate = primaryEstimates[PROCUREMENT_ELEMENTS]
        history.push(getEstimateHistoryObj({
          app: procuementElementsNewApp.name,
          realEstateName,
          accountId: selectedAccountId,
          estimateId: primaryEstimate,
          realEstateId
        }))
      }}
      testid={procuementElementsNewApp.testid}>
      {procuementElementsNewApp.imgSrc ?
        <procuementElementsNewApp.imgSrc /> :
        <Icon iconName={procuementElementsNewApp.iconName} testid={procuementElementsNewApp.testid} />
      }
    </IconButton>
  )
  // getIsFeatureEnabledInSet(FEATURE_NEW_PROCUREMENT_ELEMENTS) ? procurementElementsNewIconButton : null
  const iconButtons = apps
    .reduce((result: Array<React$Element<typeof IconButton>>, app: Object): React$Element<typeof IconButton>[] => (
      !moduleIds.includes(app.moduleId) && app.name !== REAL_ESTATES ? result :
        [
          ...result,
          <IconButton
            appName={app.name}
            key={app.testid}
            label={t(app.label)}
            active={isSelected(app.name)}
            onClick={() => {
              if (activeEdit) {
                dispatch(enableAllWidgets())
              }
              const primaryEstimate = primaryEstimates[app.name]
              if (app.name === REAL_ESTATES) {
                history.push({
                  pathname: summaryUrl,
                  search: createQueryParamsString({ accountId: selectedAccountId, realEstateId })
                })
              } else if (primaryEstimate) {
                history.push(getEstimateHistoryObj({
                  app: app.name,
                  realEstateName,
                  accountId: selectedAccountId,
                  estimateId: primaryEstimate,
                  realEstateId
                }))
              }
            }}
            testid={app.testid}>
            {app.imgSrc ?
              <app.imgSrc /> :
              <Icon iconName={app.iconName} testid={app.testid} />
            }
          </IconButton>
        ]
    ), [])
  if (getIsFeatureEnabledInSet(FEATURE_NEW_PROCUREMENT_ELEMENTS)) {
    iconButtons.push(procurementElementsNewIconButton)
  }
  return (
    <ActionBar anchor='left' >
      {iconButtons}
    </ActionBar>
  )
}

export default AppBar
