// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { borderRadiuses, boxShadows } from 'frontend-assets'
import { find, isArray } from 'lodash'
import { connect } from 'react-redux'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'

import Form from './Form'
import theme from '../../../../styles/theme'

const { borderRadiusNormal } = borderRadiuses
const { boxShadowComponent } = boxShadows

const styles = ({ palette }: Object): Object => ({
  floatingForm: {
    minHeight: '400px',
    overflow: 'hidden',
    padding: '20px 4px 10px',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '36px 16px 16px 16px',
    minHeight: '300px',
    backgroundColor: palette.white,
    zIndex: '10',
    boxShadow: boxShadowComponent,
    borderRadius: borderRadiusNormal
  },
  arrow: {
    position: 'absolute',
    backgroundColor: palette.white,
    top: '-6px',
    left: 'calc(50% - 10px)',
    width: '20px',
    height: '20px',
    boxShadow: '-5px -5px 10px -4px rgba(0, 0, 0, 0.12)',
    zIndex: '9',
    transform: 'rotate(45deg)'
  },
  title: {
    ...theme.typography.classes.h5,
    color: palette.dark80,
    margin: '0 0 26px 0',
    width: '100%',
    whiteSpace: 'no-wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'normal'
  },
  formApproach: {
    ...theme.typography.classes.bodyDefaultBold,
    color: palette.dark80,
    marginBottom: '5px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '390px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '-10px',
    padding: '5px 0'
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  radio: {
    color: palette.dark40
  },
  radioSelected: {
    color: palette.primary100
  },
  radioLabel: {
    color: palette.dark80,
    ...theme.typography.classes.bodyDefault
  }
})

type MappedProps = {|
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>
|}

type CellContent = { value: number, localizedName: string }
export type FloatingFormContent = {|
  columnName: string, // columnName
  selectedCell: CellContent, // currently selected cell item
  mountCallback: (columnName: string, selectedValue: number) => {},
  title: string, // the title part for the Floating form label: "title: columnName"
  radioControlLabel: string, // label for the radio control box
|}

type Props = {|
  ...FloatingFormContent,
  ...MappedProps,
  classes: Object, // classes object created by withstyles function
  disabled?: boolean, // if inputs should be disabled or not. Correlates to save pattern.
  children: any, // child components, mostly Forms
|}

type State = {|
  openForm: string | null // currently opened form, or null if there's only one form
|}

export class FloatingForm extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
    mountCallback: () => {},
    title: '',
    radioControlLabel: '',
    columnName: ''
  }

  state = {
    openForm: isArray(this.props.children) ? this.props.children[0].props.formName : null
  }

  componentDidMount() {
    const { columnName, selectedCell, mountCallback } = this.props
    mountCallback(columnName, Number(selectedCell.value))
  }

  componentDidUpdate(prevProps: Object) {
    const {
      columnName, selectedCell, mountCallback, languageCode
    } = this.props
    // used mainly for getting the inputs upon FloatingForm open in RelationalSelector containers
    if (prevProps.selectedCell.value !== selectedCell.value) {
      mountCallback(columnName, selectedCell.value)
    }
    if (languageCode !== prevProps.languageCode) {
      mountCallback(columnName, selectedCell.value)
    }
  }

  renderChildren = () => {
    const { children, disabled } = this.props
    const kids = React.Children.toArray(children)
    const childComponents = React.Children.map(kids, (child: Object) => React.cloneElement(child, { disabled }))

    if (kids.length > 1) {
      return find(childComponents, (child: any) => child.props.formName === this.state.openForm)
    }
    return childComponents
  }

  toggleForm = (e: SyntheticEvent<any>): void => this.setState({ openForm: e.currentTarget.value })

  static Form = (props: Object) => <Form {...props} />

  header(): React$Element<any> {
    const { classes, selectedCell, title = '' } = this.props
    return (
      <h3 className={classes.title}>
        {`${title}: ${selectedCell.localizedName}`}
      </h3>
    )
  }

  radioGroup(): React$Element<any> {
    const { classes, children, radioControlLabel } = this.props
    const { openForm } = this.state

    return (
      <FormControl>
        <FormLabel className={classes.formApproach}>{radioControlLabel}</FormLabel>
        <RadioGroup
          data-testid='test-radio-group'
          aria-label='sizing'
          name='formControl'
          className={classes.radioGroup}
          value={openForm || children[0].props.formName} // open the first form if none are chosen
          onChange={this.toggleForm}>
          {/* For each child (Form), render a radio button so we can switch between them. Automates the process
              so any amount of children will be handled. */}
          { React.Children.map(children, (child: any) => (
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              label={child.props.formLabel}
              value={child.props.formName}
              control={<Radio
                data-testid={`FloatingForm-radiobtn-${child.props.formName}`}
                className={(openForm === child.props.formName && classes.radioSelected) || classes.radio}
                color='default' />} />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  render(): React$Element<any> {
    const { classes, children } = this.props

    // don't render any radio buttons if there's only one form
    const renderRadio = React.Children.toArray(children).length > 1

    return (
      <div className={classes.floatingForm}>
        <div className={classes.paper}>
          <div className={classes.arrow} />
          {this.header()}
          { renderRadio ? this.radioGroup() : null }
          <div className={classes.form}>
            { this.renderChildren() }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  app: {
    languageCode
  }
}: TVDReduxStore): MappedProps => ({
  languageCode
})

export default connect(mapStateToProps)(withStyles(styles)(FloatingForm))
