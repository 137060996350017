// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'

import HierarchicalListContainer from '../../../containers/HierarchicalListContainer/HierarchicalListContainer'
import TextButton from '../../../common/TextButton/TextButton'
import ListSearchField from '../../../common/lists/ListSearchField/ListSearchField'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import InputField from '../../../common/InputField/InputField'

import { DESCRIPTION, DRIVER } from '../../../../constants/attributes'
import { ACTIVITY_REGISTRY_WIDGET, WOPACTIVITYGROUP, ACTIVITY_STRUCTURE } from '../../../../constants/contentTypes'
import {
  getRegistryActivitiesRequest,
  postActivityScheduleActivitiesWithItemIdRequest,
  getEnumWithEnumRequest
} from '../../../../utils/generated-api-requests/wop'
import { setListColumns } from '../../../../actions/list'
import { setCalculationActive } from '../../../../actions/app'
import { postPolling } from '../../../../actions/postPolling'
import { sortListItemsAlphabetically } from '../../../../utils/listUtils'
import { updateSentient } from '../../../../actions/sentients'


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'auto'
  },
  inputsWrapper: {
    marginTop: '10px',
    marginLeft: '50px',
    display: 'flex',
  },
  dropdownWrapper: {
    marginTop: '2px',
    marginLeft: '50px'
  }
}

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type MappedProps = {|
  listFilter: string | typeof undefined, // the set filter from Store to a list
  listItems: TVDListItems, // items in the list
  application: string // currently selected application
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // start post polling
  dispatchSetListColumns: Function,
  dispatchSetLoading: Function,
  dispatchUpdateSentient: (sentientStoreId: string) => void // update a single sentient component
|}

type ReceivedProps = {|
  resourceId: string, // id of the row that opened CreateFromPricing
  rowType: string, // type of the row from which the registry was opened from
  rowId: number, // id of the row from which the registry was opened from
  rowParentId: number // parentId of the row from which the registry was opened from
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

// Only one this type of list can be in the app
const listId = ACTIVITY_REGISTRY_WIDGET

type State = {|
  classificationDropdown: Object, // current selection, items and disabled status for the classification dropdown
  modifiedRows: { [string]: number | string }, // modified row data
|}

export class ActivityRegistry extends Component<Props, State> {
  state = {
    classificationDropdown: {
      items: [{ localizedName: this.props.t('dropdowns._ALL_'), value: 'ALL' }],
      selection: 'ALL',
      disabled: false
    },
    modifiedRows: {}
  }

  componentDidMount() {
    this.getDropdownContent()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const classID = this.state.classificationDropdown.selection === 'ALL' ? undefined : this.state.classificationDropdown.selection
    if (prevState.classificationDropdown.selection !== this.state.classificationDropdown.selection) {
      this.props.dispatchSetLoading()
      getRegistryActivitiesRequest(
        { query: { classificationId: classID } },
        { listId: ACTIVITY_REGISTRY_WIDGET }
      )
    }
  }

  getDropdownContent = () => {
    getEnumWithEnumRequest(
      {
        path: { enumParam: 'buildingclassification' },
        query: { category: 'activityRegister' },
      },
      {},
      (parsedResponse: Array<TVDEnum>) => {
        this.setState((state: State) => ({
          classificationDropdown: {
            ...state.classificationDropdown,
            items: [
              state.classificationDropdown.items[0],
              ...parsedResponse
            ]
          }
        }))
      }
    )
  }

  getClassificationDropdown = (): React$Element<typeof DropdownMenu> => (
    <DropdownMenu
      title={this.props.t('operatingProfile._ATTRIBUTE_CLASSIFICATION_')}
      disabled={this.state.classificationDropdown.disabled}
      items={this.state.classificationDropdown.items}
      onChange={(classificationId: number) => {
         this.setState({
           classificationDropdown: {
             ...this.state.classificationDropdown,
             selection: classificationId
            }
          })
        }}
      defaultValue={this.state.classificationDropdown.selection} />
  )

  handleInput = (content: Object, row: TVDWOPRegistryItem) => (input: string | number) => {
    this.setState((oldState: State) => ({
      modifiedRows: { ...oldState.modifiedRows, [row.id]: input }
    }))
  }

  searchField(): React$Element<'div'> {
    const { classes } = this.props

    return (
      <div className={classes.searchFieldWrapper}>
        <ListSearchField
          listId={listId}
          filteredTypes={[]} />
      </div>
    )
  }

  getAddButton(row: TVDWOPRegistryItem): React$Element<TextButton> {
    const {
      t,
      rowType,
      rowId,
      listItems,
      rowParentId,
      dispatchPostPolling,
      dispatchUpdateSentient
    } = this.props

    const getRowData = (): string | number | typeof undefined => this.state.modifiedRows[row.id] || listItems[row.id].columnData.Driver?.value

    const onAddButtonClick = () => {
      const parentId = rowType === WOPACTIVITYGROUP ? rowId : rowParentId
      postActivityScheduleActivitiesWithItemIdRequest(
        { path: { itemId: parentId }, query: { registryId: row.registryItemId }, body: { Driver: getRowData() } },
        {}, () => {
          dispatchPostPolling()
          dispatchUpdateSentient(ACTIVITY_STRUCTURE)
        },
        null,
        { disableRefreshEstimateLock: true }
      )
    }

    return (
      <TextButton
        id={`${ACTIVITY_REGISTRY_WIDGET}-add-button`}
        variant='small'
        text={t('buttons._ADD_')}
        onClick={onAddButtonClick} />
    )
  }

  render(): React$Element<any> {
    const {
      classes,
      dispatchSetListColumns,
      dispatchSetLoading,
      t
    } = this.props


    const didMountCb = () => {
      dispatchSetLoading()
      getRegistryActivitiesRequest({}, { listId: ACTIVITY_REGISTRY_WIDGET }, () => {})
      dispatchSetListColumns([
        { propertyName: DESCRIPTION, localizedName: t('activityRegistryWidget._DESCRIPTION_') },
        { propertyName: DRIVER, localizedName: t('activityRegistryWidget._DRIVER_') }
      ])
    }

    return (
      <div className={classes.root}>
        <div className={classes.inputsWrapper}>
          {this.searchField()}
          <div className={classes.dropdownWrapper}>
            {this.getClassificationDropdown()}
          </div>
        </div>
        <HierarchicalListContainer
          listType={ACTIVITY_REGISTRY_WIDGET}
          listId={ACTIVITY_REGISTRY_WIDGET}
          testId='ActivityRegistry'
          editableColumns={[]}
          sortFn={sortListItemsAlphabetically}
          addedColumns={[{ propertyName: 'addButtonColumn', localizedName: '', dataType: '' }]}
          columnsVisibleOnHover={['addButtonColumn']}
          didMountCallback={didMountCb}
          onRowClick={() => {}}
          initialColumnWidths={{
            Description: 650
          }}
          wrappedCellContents={{
            Description: ({ content }: TVDWOPRegistryWrappedCellCallbackParameters): React$Element<typeof DescriptionCell> =>
              <DescriptionCell testId='listRowCell-Description-text' text={content} />,
            Driver: ({ content, row }: TVDWOPRegistryWrappedCellCallbackParameters): React$Element<typeof InputField> => (
              <InputField
                dataType={content.dataType}
                initialValue={content.value}
                onChange={this.handleInput(content, row)}
                unit={content.unit} />
            ),
            addButtonColumn: ({ row }: TVDWOPRegistryWrappedCellCallbackParameters): React$Element<typeof TextButton> => this.getAddButton(row)
          }} />
      </div>
    )
  }
}

const mapStateToProps = ({ list, app }: TVDReduxStore): MappedProps => {
  const { listFilter, listItems } = list[listId] || {}

  return { listFilter, listItems, application: app.application }
}

function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    dispatchPostPolling: () => { dispatch(postPolling()) },
    dispatchSetListColumns: (columns: Array<TVDListItemColumn>) => { dispatch(setListColumns(listId, columns)) },
    dispatchSetLoading: () => { dispatch(setCalculationActive()) },
    dispatchUpdateSentient: (sentientStoreId: string) => { dispatch(updateSentient(sentientStoreId)) }
  }
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ActivityRegistry)
