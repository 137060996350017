// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { pick } from 'lodash'
import {
  CLOSE_WIDGET_SUCCESSFUL,
  MARK_AS_MODIFIED,
  TOGGLE_WIDGET_MODIFIED,
  SET_SELECTED_TAB,
  OPEN_CONTENT_WIDGET,
  CLEAR_WIDGETS,
  SET_WIDGET_CONTENT_PROPS,
  ENABLE_ALL_WIDGETS,
  UPDATE_WIDGET_TITLE
} from '../actions/widgets/'
import { getFilteredWidgets } from '../utils/widgetReducerUtils'
import { SET_WIDGET_ACTIVE } from '../actions/app'
import { reduceSuccessfulEmbeddedActions, type ReduceSuccessfulEmbeddedActionsCallbackArguments } from '../utils/reducerMergeUtil'
import { ELEMENT_SCHEDULE_ITEM } from '../constants/schemaKeys'

const initialState = {}

type OpenContentWidgetActionPayload = {|
  ...TVDOpenContentWidgetArguments, // argument object that you can give to openContenWidget action
  widgetLocation: {| top: number, right: number |} // locationMiddleware provided location of the widget
|}

export default function widgetReducer(state: Object = initialState, action: Object = {}): Object {
  switch (action.type) {
    case OPEN_CONTENT_WIDGET: {
      const {
        payload: {
          widgetId,
          widgetType,
          widgetTitle,
          contentProps,
          widgetLocation,
          widgetResultBarStoreSource,
          widgetEnableAllWidgetsOnClose,
          widgetResultBarFormatOptions,
          widgetPreferXYComponentPreferences,
          widgetPreferSizeComponentPreferences
        }
      }: {| payload: OpenContentWidgetActionPayload, type: string |} = action
      const existingWidget = state[widgetId]
      return {
        ...getFilteredWidgets(state, widgetType),
        [widgetId]: {
          widgetId,
          widgetType,
          widgetTitle,
          ...(!!existingWidget && existingWidget.contentProps ?
            { contentProps: { ...existingWidget.contentProps, ...contentProps } } :
            { contentProps }
          ),
          modified: false,
          widgetLocation,
          widgetResultBarStoreSource,
          widgetEnableAllWidgetsOnClose,
          widgetResultBarFormatOptions,
          widgetPreferXYComponentPreferences,
          widgetPreferSizeComponentPreferences
        }
      }
    }

    case CLOSE_WIDGET_SUCCESSFUL: {
      const { payload: { widgetId: uuid } } = action
      return pick(state, Object.keys(state).filter((widgetUUID: string) => widgetUUID !== uuid))
    }

    case MARK_AS_MODIFIED: {
      const { widgetId } = action.payload

      // if widget with given widgetId doesn't exist, cancel mark as modified
      if (!state[widgetId]) return state
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId], modified: true
        }
      }
    }

    case ENABLE_ALL_WIDGETS: {
      return Object.keys(state).reduce((allWidgets: Object, widgetId: string) => ({
        ...allWidgets, [widgetId]: { ...state[widgetId], disabled: false, modified: false }
      }), {})
    }

    case TOGGLE_WIDGET_MODIFIED: {
      const { widgetId, isModified } = action.payload
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          modified: isModified
        }
      }
    }

    case SET_WIDGET_ACTIVE: {
      const { payload: { widgetId, widgetModifiedFalse } } = action
      return {
        ...Object.keys(state).reduce((allWidgets: Object, storeWidgetId: string) => ({
          ...allWidgets,
          [storeWidgetId]: {
            ...state[storeWidgetId],
            ...(widgetId === storeWidgetId ?
              {
                modified: !widgetModifiedFalse,
                disabled: false,
              } : {
                disabled: true,
              })
          }
        }), {}),
      }
    }

    case SET_SELECTED_TAB: {
      const { widgetId, tab } = action.payload
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          contentProps: {
            ...(state[widgetId].contentProps || {}),
            selectedTab: tab,
          }
        }
      }
    }

    case CLEAR_WIDGETS: {
      return {
        ...initialState,
      }
    }

    case SET_WIDGET_CONTENT_PROPS: {
      const {
        payload: {
          widgetId,
          contentProps
        }
      } = action
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          contentProps: {
            ...(state[widgetId].contentProps || {}),
            ...contentProps
          }
        }
      }
    }

    case UPDATE_WIDGET_TITLE: {
      const {
        payload: {
          widgetId,
          widgetTitle
        }
      } = action
      return {
        ...state,
        [widgetId]: {
          ...state[widgetId],
          widgetTitle
        }
      }
    }

    default:
      break
  }

  return reduceSuccessfulEmbeddedActions({
    action,
    state,
    conditionFn: () => action && action.payload && action.payload.widgetId,
    cb: ({ schemaKey, newState }: ReduceSuccessfulEmbeddedActionsCallbackArguments) => {
      const { widgetId } = action.payload
      switch (schemaKey) {
        case ELEMENT_SCHEDULE_ITEM: {
          return {
            ...state,
            [widgetId]: {
              ...state[widgetId],
              widgetTitle: `${action.payload.parsedResponse.columnData.Description}`
            }
          }
        }
        default:
          return newState
      }
    }
  })
}
