// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import TextButton from '../../common/TextButton/TextButton'
import { createQueryParamsString } from '../../../utils/urlUtil'
import theme from '../../../styles/theme'

type MappedProps = {|
  selectedAccountId?: string, // user selected account id
|}

type Props = {
  ...MappedProps,
  t: Function, // translation function
  classes: Object, // classes-object created by withstyles function
  location: Object, // url details
}

type State = {|
  title?: string, // translated text to show as title
  body?: string, // translated text to show as the bod text under title
|}
const styles = ({ palette }: Object): Object => ({
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    color: palette.dark80
  },
  textWrapper: {
    justifyContent: 'center',
  },
  titleText: {
    fontWeight: 'normal',
    ...theme.typography.classes.h1,
    marginTop: '64px',
    marginBottom: '32px',
  },
  bodyText: {
    ...theme.typography.classes.bodyBig,
    marginBottom: '20px',
    justifyContent: 'center',
    display: 'flex',
  },
  root: {
    overflow: 'hidden'
  },
  contactInformation: {
    ...theme.typography.classes.bodyBig,
    marginBottom: '48px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  callCost: {
    marginTop: '14px',
    ...theme.typography.classes.bodyTiny,
  },
  button: {
    justifyContent: 'center',
    display: 'flex'
  },
  boldText: {
    ...theme.typography.classes.bodyBigBold,
  }
})

export class ErrorScene extends Component<Props, State> {
  get URLSearchParamHttpCode(): string {
    const { location: { search } } = this.props
    const httpCode = new URLSearchParams(search).get('httpCode') || '404'
    return httpCode
  }

  get httpCodeTranslations(): {| title: string, body: string, imgUrl: string |} {
    const httpCode = this.URLSearchParamHttpCode
    switch (true) {
      // All 500 - 599 errors
      case httpCode[0] === '5':
        return {
          title: this.getErrorTextTranslation('SERVER_ERROR_TITLE'),
          body: this.getErrorTextTranslation('SERVER_ERROR_BODY'),
          imgUrl: '/assets/images/error/500.svg'
        }
      case httpCode === '404':
      default:
        return {
          title: this.getErrorTextTranslation('NOT_FOUND_TITLE'),
          body: this.getErrorTextTranslation('NOT_FOUND_BODY'),
          imgUrl: '/assets/images/error/404.svg'
        }
    }
  }

  redirectLink = (): string => {
    const currentPath = window.location.pathname

    if (currentPath.includes('account-management')) return '/account-management'
    return '/'
  }

  getErrorTextTranslation(key: string): string {
    const { t } = this.props
    return t(`errors._${key}_`)
  }

  render(): React$Element<any> {
    const { classes, t, selectedAccountId } = this.props
    const { title, body, imgUrl } = this.httpCodeTranslations

    return (
      <div data-testid={`error-scene-httpcode-${this.URLSearchParamHttpCode}`} className={classes.root}>
        <div className={classes.contentWrapper}>
          <img alt='Error' src={imgUrl} />
          <div className={classes.textWrapper}>
            <h1 className={classes.titleText}>{title}</h1>
            <div className={classes.bodyText}>{body}</div>
            <div className={classes.contactInformation}>
              <div className={classes.contanctInfromationTitle}>{t('errorPage._CONTACT_INFORMATION_')}:</div>
              <div className={classes.boldText}>Haahtela Helpdesk</div>
              <div>tuki@haahtela.fi</div>
              <div>{t('errorPage._PHONE_AND_TIME_')}</div>
              <div className={classes.callCost}>{t('errorPage._CALL_COST_')}</div>
            </div>
            <Link to={{ pathname: this.redirectLink(), search: createQueryParamsString({ accountId: selectedAccountId }) }}>
              <div className={classes.button}>
                <TextButton text={t('errorPage._RETURN_FRONT_PAGE_')} variant='login' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app: { selectedAccountId } }: TVDReduxStore): MappedProps => ({
  selectedAccountId
})

export default withStyles(styles)(withRouter(withTranslation('translations')(connect(mapStateToProps)(ErrorScene))))
