// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { map, omit } from 'lodash'
import Axios from 'axios'

import { dispatch, getState } from '../store'
import type { TextButtonProps } from '../components/common/TextButton/TextButton'
import { type Props as WidgetProps } from '../components/containers/widgets/Widget'

import { getModifiedProperties, getResetDefaultProperties, getModifiedListItems } from './listUtils'
import { patchOperationsToArray } from './patchOperationUtil'
import * as types from '../constants/contentTypes'
import { QUANTITYTOTAL, QUANTITY } from '../constants/attributes'
import {
  PROPERTIES_TAB,
  EQUIPMENTS_TAB,
  SURFACES_TAB,
  SPACES,
  ELEMENTS,
  RENOVATION_SITE_TAB,
  RENOVATION_SPACES_TAB,
  RENOVATION_BUILDING_TAB,
  RENOVATION_SPACEGROUPS_TAB
} from '../constants/moduleConstants'
import {
  CIRCULATION,
  FACILITY_SERVICES,
  TECHNICAL_BUILDING_SERVICES,
  PROTECTED_CIRCULATION
} from '../constants/registryConstants'

import { postPolling } from '../actions/postPolling'
import { closeWidget, updateWidgetTitle } from '../actions/widgets'
import { saveMultiEditProperties } from './requests/properties'
import { saveEquipmentRegistryList, saveSurfacesRegistryList, saveAreaEquipmentRegistryList, saveAssemblyList } from './requests/list'
import { patchPriceitemPropertiesWithIdRequest, patchAssemblyPropertiesWithIdRequest } from './generated-api-requests/buildingelements'
import {
  patchDesignModelStoriesWithStoryIdRequest,
  patchEstimatePropertiesWithEstimateIdRequest,
  patchSpaceScheduleSpacesPropertiesWithIdRequest,
  patchSpaceScheduleSpaceGroupsPropertiesWithIdRequest,
  patchSpacesSurfacesPropertiesWithIdRequest,
  patchEstimateEquipmentPropertiesWithEquipmentIdRequest,
  patchSpacesEquipmentPropertiesWithIdRequest,
  putCommonFunctionsWithFunctionTypeRequest,
  putCommonFunctionsWithEstimateIdRequest
} from './generated-api-requests/spaces'
import {
  patchRenovationSiteWithEstimateIdRequest,
  patchRenovationBuildingWithEstimateIdRequest,
  patchRenovationSpacesWithEstimateIdRequest,
  patchRenovationSpaceGroupsWithEstimateIdRequest
} from './generated-api-requests/spaces_renovation'


// BUTTONS FOR PROPERTIES TAB IN SPACE AND SPACEGROUP WIDGETS
const propertiesDataTableButtons = (props: Object): Array<TextButtonProps> => {
  const {
    resourceListId,
    widgetType,
    contentProps,
    widgetId
  } = props
  const { spaceId, propertiesStoreId } = contentProps
  return [
    {
      id: 'propertiesDataTableSaveButton',
      disabled: !props.modified,
      onClick: () => {
        const { properties } = getState()
        const fn = widgetType === 'SPACEGROUP' ?
          patchSpaceScheduleSpaceGroupsPropertiesWithIdRequest :
          patchSpaceScheduleSpacesPropertiesWithIdRequest
        fn(
          {
            path: { id: spaceId },
            body: getModifiedProperties(properties[propertiesStoreId]),
            query: getResetDefaultProperties(properties[propertiesStoreId]),
          },
          {
            listId: resourceListId
          },
          () => {
            dispatch(postPolling())
          }
        )
      },
      text: props.t('widgets._SAVE_'),
      showSpinner: props.activeCalculation
    },
    {
      id: 'propertiesDataTableCancelButton',
      onClick: () => dispatch(closeWidget(widgetId)),
      text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text'
    }
  ]
}

// BUTTONS FOR EQUIPMENTS TAB IN SPACE WIDGET
const getEquipmentsButtons = (props: Object): Array<TextButtonProps> => {
  const { contentProps, widgetId } = props
  return [
    {
      id: 'equipmentsButtonSave',
      onClick: () => { saveEquipmentRegistryList(contentProps.equipmentsListId, contentProps.spaceId) },
      disabled: props.disabled || !props.modified,
      text: props.t('widgets._SAVE_'),
      showSpinner: props.activeCalculation
    },
    {
      id: 'equipmentsButtonCancel',
      onClick: () => dispatch(closeWidget(widgetId)),
      text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text',
      disabled: props.disabled,
    }
  ]
}

const getSurfacesButtons = (props: Object): Array<TextButtonProps> => {
  const {
    contentProps: {
      surfacesListId,
      spaceId
    } = {},
    activeCalculation,
    disabled,
    modified,
    t,
    isModified,
    widgetId
  } = props
  return [
    {
      id: 'surfacesButtonSave',
      onClick: () => {
        saveSurfacesRegistryList(surfacesListId, spaceId)
      },
      disabled: disabled || !modified,
      text: t('widgets._SAVE_'),
      showSpinner: activeCalculation
    },
    {
      id: 'surfacesButtonCancel',
      onClick: () => dispatch(closeWidget(widgetId)),
      text: isModified ? t('widgets._CANCEL_') : t('widgets._CLOSE_'),
      variant: 'text',
      disabled,
    }
  ]
}

// BUTTONS FOR CALCULATION_PROPERTIES WIDGET - PROPERTIES TAB
const calculationPropertiesDataTableButtons = ({
  modified,
  t,
  activeCalculation,
  contentProps: { propertiesStoreId, updateTechnicalBuildingServices },
  widgetId
}: Object): Array<TextButtonProps> => [
  {
    id: 'propertiesDataTableSaveButton',
    disabled: !modified,
    onClick: () => {
      const { properties } = getState()
      const { resetProperties } = getResetDefaultProperties(properties[propertiesStoreId])
      patchEstimatePropertiesWithEstimateIdRequest(
        {
          body: getModifiedProperties(properties[propertiesStoreId]),
          query: {
            resetProperties,
            updateBuildingServices: updateTechnicalBuildingServices
          }
        },
        {},
        () => {
          dispatch(postPolling())
        }
      )
    },
    text: t('widgets._SAVE_'),
    showSpinner: activeCalculation
  },
  {
    id: 'propertiesDataTableCancelButton',
    onClick: () => dispatch(closeWidget(widgetId)),
    text: modified ? t('widgets._CANCEL_') : t('widgets._CLOSE_'),
    variant: 'text'
  }
]

// BUTTONS FOR CALCULATION_PROPERTIES WIDGET - EQUIPMENTS TAB
const getCalculationEquipmentsButtons = (props: Object): Array<TextButtonProps> => [
  {
    id: 'equipmentsButtonSave',
    onClick: () => {
      // resourceListId prop is passed to Equipments component
      // in shape of CALCULATION_PROPERTIES-EQUIPMENTS in CalculationProperties.jsx
      // so we need to add EQUIPMENTS postfix to resourceListId here
      dispatch(saveAreaEquipmentRegistryList(props.contentProps.listStoreId))
    },
    disabled: props.disabled || !props.modified,
    text: props.t('widgets._SAVE_'),
    showSpinner: props.activeCalculation
  },
  {
    id: 'equipmentsButtonCancel',
    onClick: () => dispatch(closeWidget(props.widgetId)),
    text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
    variant: 'text',
    disabled: props.disabled,
  }
]

// BUTTONS FOR STORY WIDGET
const getStoryButtons = (props: Object): Array<TextButtonProps> => [
  {
    id: 'floorProperties-SaveButton',
    onClick: () => {
      const {
        designModel: {
          openStory,
          options: {
            updateProtectedCirculation,
            updateStructures,
          }
        }
      } = getState()
      const modifiedProperties = getModifiedProperties(openStory.properties)
      patchDesignModelStoriesWithStoryIdRequest(
        {
          path: { storyId: props.contentProps.resourceId },
          body: modifiedProperties,
          query: {
            updateProtectedCirculation,
            updateStructures,
          }
        },
        {},
        () => dispatch(postPolling())
      )
    },
    disabled: !props.modified,
    text: props.t('widgets._SAVE_'),
    showSpinner: props.activeCalculation
  }, {
    id: 'floorProperties-CancelButton',
    variant: 'text',
    onClick: () => dispatch(closeWidget(props.widgetId)),
    text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
  }
]

// BUTTONS FOR PRICEITEM / EQUIPMENT WIDGET
const getPriceItemButtons = (props: Object): Array<TextButtonProps> => [
  {
    id: 'Save',
    disabled: !props.modified,
    onClick: () => {
      const { properties } = getState()
      const {
        widgetType,
        contentProps: {
          propertiesStoreId,
          priceItemId,
          equipmentId,
          listStoreId
        },
        widgetId
      } = props
      const modifiedProperties = getModifiedProperties(properties[propertiesStoreId])

      const cb = () => {
        dispatch(postPolling({}, () => {
          const widgetTitle = getState().list[listStoreId].listItems[widgetId].columnData.Description
          dispatch(updateWidgetTitle(
            widgetId,
            { widgetTitle }
          ))
        }))
      }
      switch (true) {
        case widgetType === types.PRICEITEM: {
          patchPriceitemPropertiesWithIdRequest({ path: { id: priceItemId }, body: modifiedProperties }, {}, cb, null)
          break
        }
        case widgetType === types.EQUIPMENT: {
          patchEstimateEquipmentPropertiesWithEquipmentIdRequest(
            { path: { equipmentId }, body: modifiedProperties },
            {},
            cb,
            null,
          )
          break
        }
        default:
          break
      }
    },
    text: props.t('widgets._SAVE_'),
    showSpinner: props.activeCalculation
  },
  {
    id: 'elementButtonCancel',
    onClick: () => {
      dispatch(closeWidget(props.widgetId))
    },
    text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
    variant: 'text'
  }
]


// Buttons for EQUIPMENTS widget in SPACES module
const getSpaceEquipmentsButtons = (props: Object): Array<TextButtonProps> => [
  {
    id: 'elementButtonSave',
    disabled: !props.modified,
    onClick: () => {
      const { properties } = getState()
      const {
        contentProps: {
          propertiesStoreId,
          equipmentId,
          spaceId,
          listStoreId
        },
        widgetId
      } = props
      const modifiedProperties = getModifiedProperties(properties[propertiesStoreId])
      patchSpacesEquipmentPropertiesWithIdRequest({ path: { id: equipmentId, spaceId }, body: modifiedProperties }, {}, () => {
        dispatch(postPolling({}, () => {
          const widgetTitle = getState().list[listStoreId].listItems[widgetId].columnData.Description
          dispatch(updateWidgetTitle(
            widgetId,
            { widgetTitle }
          ))
        }))
      })
    },
    text: props.t('widgets._SAVE_'),
    showSpinner: props.activeCalculation
  },
  {
    id: 'elementButtonCancel',
    onClick: () => {
      const { widgetId } = props
      dispatch(closeWidget(widgetId))
    },
    text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
    variant: 'text',
    disabled: props.activeCalculation
  }
]


// Buttons for EQUIPMENT widget in SPACES - AREA_EQUIPMENT
const getEstimateEquipmentProperties = (props: Object): Array<TextButtonProps> => [
  {
    id: 'elementButtonSave',
    disabled: !props.modified,
    onClick: () => {
      const { properties } = getState()
      const { contentProps: { propertiesStoreId, equipmentId } } = props
      const body = getModifiedProperties(properties[propertiesStoreId])
      patchEstimateEquipmentPropertiesWithEquipmentIdRequest({ path: { equipmentId }, body }, {}, () => {
        dispatch(postPolling())
      })
    },
    text: props.t('widgets._SAVE_'),
    showSpinner: props.activeCalculation
  },
  {
    id: 'elementButtonCancel',
    onClick: () => {
      dispatch(closeWidget(props.widgetId))
    },
    text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
    variant: 'text'
  }
]

// BUTTONS FOR ASSEMBLY AND SPACESASSEMBLY WIDGET
const getAssemblyButtons = (props: Object): Array<TextButtonProps> => [
  {
    id: 'assemblyButtonSave',
    disabled: !props.modified,
    onClick: () => {
      const { properties, list } = getState()
      const {
        contentProps,
        application,
        widgetType,
        resourceListId,
        resourceId
      } = props
      const id = resourceId || contentProps.resourceId

      switch (application) {
        case SPACES: {
          const listId = resourceListId || contentProps.attributesPropertiesStoreId
          const propertyRows = properties[listId]
          const modifiedProperties = getModifiedProperties(propertyRows)
          if (Object.keys(modifiedProperties).length) {
            patchSpacesSurfacesPropertiesWithIdRequest(
              { path: { id, spaceId: contentProps.spaceId }, body: modifiedProperties },
              { listId },
              () => dispatch(postPolling())
            )
          }

          const modifiedListItems = getModifiedListItems(list[contentProps.itemsListStoreId].listItems)
          const listItemRequests = map(modifiedListItems, (listItemProperties: Object, itemId: string) => {
            if (listItemProperties[QUANTITYTOTAL]) { // replaces property QuantityTotal with Quantity for cross endpoint compatibility
              listItemProperties = omit({ [QUANTITY]: listItemProperties[QUANTITYTOTAL] }, QUANTITYTOTAL)
            }
            return patchSpacesEquipmentPropertiesWithIdRequest(
              { path: { id: itemId, spaceId: contentProps.spaceId }, body: listItemProperties },
              { listId: contentProps.itemsListStoreId }
            )
          })

          if (listItemRequests.length) {
            Axios.all(listItemRequests).then(() => dispatch(postPolling()))
          }
          break
        }
        case ELEMENTS: {
          const listId = resourceId
          const rows = properties[listId]
          const modifiedRows = getModifiedProperties(rows)
          patchAssemblyPropertiesWithIdRequest({ path: { id }, body: modifiedRows }, {}, () => {
            saveAssemblyList(id)
          }, null)
          break
        }
        default:
          console.warn(`No PATCH properties operation for widgetType "${widgetType}" and module "${application}"`)
          break
      }
    },
    text: props.t('widgets._SAVE_'),
    showSpinner: props.activeCalculation
  },
  {
    id: 'assemblyButtonCancel',
    onClick: () => {
      dispatch(closeWidget(props.widgetId))
    },
    text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
    variant: 'text'
  }
]

const addAllCommonFunctions = () => {
  putCommonFunctionsWithEstimateIdRequest({
    body: [TECHNICAL_BUILDING_SERVICES, FACILITY_SERVICES, CIRCULATION, PROTECTED_CIRCULATION]
  }, {}, () => dispatch(postPolling()))
}

const addCommonFunctionsByFunctionType = (listId: string, functionType: string) => {
  const { list } = getState()
  const currentList = list[listId]
  const referenceIds = map(currentList.listItems, (item: Object) => item.referenceId)
  putCommonFunctionsWithFunctionTypeRequest({ path: { functionType }, body: referenceIds }, {}, () => dispatch(postPolling()))
}

// Buttons for common_functions with widgetId of "CREATE_OTHER_SPACES"
const getCreateOtherSpacesWidgetButtons = (props: Object): Array<TextButtonProps> => [
  {
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    onClick: () => addAllCommonFunctions(),
    type: 'primary',
    text: props.t('buttons._ADD_AND_REPLACE_'),
    id: 'Save-addAllCommonFunctions'
  },
  {
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    onClick: () => dispatch(closeWidget(props.widgetId)),
    variant: 'text',
    text: props.t('buttons._CANCEL_'),
  }
]

// BUTTONS FOR COMMON_FUNCTIONS WIDGET WITH FUNCTIONTYPE: circulation
const getCreateCirculationButtons = (props: Object): Array<TextButtonProps> => [
  {
    onClick: () => addCommonFunctionsByFunctionType(props.contentProps.resourceListId, CIRCULATION),
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    type: 'primary',
    text: props.t('buttons._ADD_AND_REPLACE_'),
  },
  {
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    onClick: () => dispatch(closeWidget(props.widgetId)),
    variant: 'text',
    text: props.t('buttons._CANCEL_'),
  }
]

// BUTTONS FOR COMMON_FUNCTIONS WIDGET WITH FUNCTIONTYPE: facilityServices

const getCreateFacilityServicesButtons = (props: Object): Array<TextButtonProps> => [
  {
    onClick: () => addCommonFunctionsByFunctionType(props.contentProps.resourceListId, FACILITY_SERVICES),
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    type: 'primary',
    text: props.t('buttons._ADD_AND_REPLACE_'),
  },
  {
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    onClick: () => dispatch(closeWidget(props.widgetId)),
    variant: 'text',
    text: props.t('buttons._CANCEL_'),
  }
]

// BUTTONS FOR COMMON_FUNCTIONS WIDGET WITH FUNCTIONTYPE: technicalBuildingServices
const getCreateTechnicalBuildingServicesButtons = (props: Object): Array<TextButtonProps> => [
  {
    onClick: () => addCommonFunctionsByFunctionType(props.contentProps.resourceListId, TECHNICAL_BUILDING_SERVICES),
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    type: 'primary',
    text: props.t('buttons._ADD_AND_REPLACE_'),
  },
  {
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    onClick: () => dispatch(closeWidget(props.widgetId)),
    variant: 'text',
    text: props.t('buttons._CANCEL_'),
  }
]

// BUTTONS FOR COMMON_FUNCTIONS WIDGET WITH FUNCTIONTYPE: protectedCirculation
const getCreateProtectedCirculationButtons = (props: Object): Array<TextButtonProps> => [
  {
    onClick: () => addCommonFunctionsByFunctionType(props.contentProps.resourceListId, PROTECTED_CIRCULATION),
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    type: 'primary',
    text: props.t('buttons._ADD_AND_REPLACE_'),
  },
  {
    disabled: props.isEstimateFrozen || !props.isEstimateLockedToCurrentUser,
    onClick: () => dispatch(closeWidget(props.widgetId)),
    variant: 'text',
    text: props.t('buttons._CANCEL_'),
  }
]

// BUTTONS MULTIEDIT SPACE OR SPACEGROUP WIDGETS
const getMultiEditWidgetButtons = (props: Object): Array<TextButtonProps> => {
  const {
    contentProps: {
      listStoreId,
      propertiesStoreId,
      overwittenSelectedRows
    },
    widgetId,
    widgetType,
  } = props

  return [
    {
      id: 'propertiesDataTableSaveButton',
      disabled: props.isEstimateFrozen || !props.modified || !props.isEstimateLockedToCurrentUser,
      onClick: () => saveMultiEditProperties({
        listStoreId,
        propertiesStoreId,
        containerType: types.multiEditContainerTypes[widgetType],
        overwittenSelectedRows
      }),
      text: props.t('widgets._SAVE_'),
      showSpinner: props.activeCalculation
    },
    {
      id: 'propertiesDataTableCancelButton',
      onClick: () => dispatch(closeWidget(widgetId)),
      text: props.modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text'
    }
  ]
}


// Switch case for every tab that can be in space widget
const getSpaceButtons = (props: Object) => {
  const { contentProps: { selectedTab } = {} } = props
  switch (selectedTab) {
    case PROPERTIES_TAB:
      return propertiesDataTableButtons(props)
    case EQUIPMENTS_TAB:
      return getEquipmentsButtons(props)
    case SURFACES_TAB:
      return getSurfacesButtons(props)
    default:
      return []
  }
}

// Switch case for every tab that can be in calculationproperties widget
const getCalculationPropertiesButtons = (props: Object) => {
  const { contentProps: { selectedTab } = {} } = props
  switch (selectedTab) {
    case PROPERTIES_TAB:
      return calculationPropertiesDataTableButtons(props)
    case EQUIPMENTS_TAB:
      return getCalculationEquipmentsButtons(props)
    default:
      return []
  }
}


const getRenovationBuildingButtons = (props: WidgetProps) => {
  const {
    widgetId,
    modified,
    activeCalculation,
    t
  } = props

  return [
    {
      id: 'renovation-building-buttons-save',
      disabled: !modified,
      onClick: () => {
        const {
          list: { [types.RENOVATION_BUILDING]: listItems },
          patchOperations: { [types.RENOVATION_BUILDING]: patchOperations }
        } = getState()
        const resetItemsValue: any = Object.values(listItems.listItems)
        const resetListItems = resetItemsValue
          .filter((listItem: TVDListItem) => listItem.modifiedColumnData && listItem.modifiedColumnData.reset)
          .map((listItem: TVDListItem) => ({
            resourceId: listItem.id,
            operationType: 'RESET',
            operationParameters: { '/columnMeta/RenovationMeasureTopicId': listItem.id }
          }))
        patchRenovationBuildingWithEstimateIdRequest(
          { body: patchOperations ? [...resetListItems, ...patchOperationsToArray(patchOperations)] : resetListItems },
          {}, () => { dispatch(postPolling()) }
        )
      },
      text: t('widgets._SAVE_'),
      showSpinner: activeCalculation
    },
    {
      id: 'renovation-site-buttons-cancel',
      onClick: () => dispatch(closeWidget(widgetId)),
      text: modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text'
    }
  ]
}

const getRenovationSiteButtons = (props: WidgetProps) => {
  const {
    widgetId,
    modified,
    activeCalculation,
    t,
  } = props
  return [
    {
      id: 'renovation-site-buttons-save',
      disabled: !modified,
      onClick: () => {
        const {
          list: { [types.RENOVATION_SITE]: listItems },
          patchOperations: { [types.RENOVATION_SITE]: patchOperations }
        } = getState()
        const resetItemsValue: any = Object.values(listItems.listItems)
        const resetListItems = resetItemsValue
          .filter((listItem: TVDListItem): Object => listItem.modifiedColumnData && listItem.modifiedColumnData.reset)
          .map((listItem: TVDListItem): Object => ({
            resourceId: listItem.id,
            operationType: 'RESET',
            operationParameters: { '/columnMeta/RenovationMeasureTopicId': listItem.id }
          }))
        patchRenovationSiteWithEstimateIdRequest(
          { body: patchOperations ? [...resetListItems, ...patchOperationsToArray(patchOperations)] : resetListItems },
          {}, () => { dispatch(postPolling()) }
        )
      },
      text: t('widgets._SAVE_'),
      showSpinner: activeCalculation
    },
    {
      id: 'renovation-site-buttons-cancel',
      onClick: () => dispatch(closeWidget(widgetId)),
      text: modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text'
    }
  ]
}

const getRenovationSpacesButtons = (props: WidgetProps) => {
  const {
    widgetId,
    modified,
    activeCalculation,
    t
  } = props
  return [
    {
      id: 'renovation-spaces-buttons-save',
      disabled: !modified,
      onClick: () => {
        const { patchOperations: { [types.RENOVATION_SPACES]: patchOperations } } = getState()
        patchRenovationSpacesWithEstimateIdRequest({ body: patchOperationsToArray(patchOperations) }, {}, () => { dispatch(postPolling()) })
      },
      text: t('widgets._SAVE_'),
      showSpinner: activeCalculation
    },
    {
      id: 'renovation-spaces-buttons-cancel',
      onClick: () => { dispatch(closeWidget(widgetId)) },
      text: modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text'
    }
  ]
}

const getRenovationSpaceGroupsButtons = (props: WidgetProps) => {
  const {
    widgetId,
    modified,
    activeCalculation,
    t
  } = props
  return [
    {
      id: 'renovation-space-groups-buttons-save',
      disabled: !modified,
      onClick: () => {
        const { patchOperations: { [types.RENOVATION_SPACEGROUPS]: patchOperations } } = getState()
        patchRenovationSpaceGroupsWithEstimateIdRequest({ body: patchOperationsToArray(patchOperations) }, {}, () => { dispatch(postPolling()) })
      },
      text: t('widgets._SAVE_'),
      showSpinner: activeCalculation
    },
    {
      id: 'renovation-space-groups-buttons-cancel',
      onClick: () => { dispatch(closeWidget(widgetId)) },
      text: modified ? props.t('widgets._CANCEL_') : props.t('widgets._CLOSE_'),
      variant: 'text'
    }
  ]
}

const getRenovationButtons = (props: WidgetProps) => {
  const { contentProps: { selectedTab } = {} } = props
  switch (selectedTab) {
    case RENOVATION_SITE_TAB: {
      return getRenovationSiteButtons(props)
    }
    case RENOVATION_SPACES_TAB: {
      return getRenovationSpacesButtons(props)
    }
    case RENOVATION_SPACEGROUPS_TAB: {
      return getRenovationSpaceGroupsButtons(props)
    }
    case RENOVATION_BUILDING_TAB: {
      return getRenovationBuildingButtons(props)
    }
    default:
      return []
  }
}

// Function to get correct buttons for each widgetType
export const getButtonsByWidgetType = (props: WidgetProps): Array<Object> => {
  const { widgetType, application } = props
  const widgetsType = widgetType
  switch (widgetsType) {
    case types.RENOVATION: {
      return getRenovationButtons(props)
    }
    case types.SPACE: {
      return getSpaceButtons(props)
    }
    case types.SPACEGROUP: {
      return getSpaceButtons(props)
    }
    case types.CALCULATION_PROPERTIES: {
      return getCalculationPropertiesButtons(props)
    }
    case types.FLOOR: {
      return getStoryButtons(props)
    }
    case types.EQUIPMENT: {
      switch (application) {
        case SPACES: {
          const { contentProps: { spaceId } = {} } = props
          // equipment widget can be opened in Spaces module from estimate equipments and from spaces equipments
          // if we don't pass a spaceId that is required for getting space equipment buttons, we know to use buttons for estimate equipments
          if (!spaceId) {
            return getEstimateEquipmentProperties(props)
          }
          return getSpaceEquipmentsButtons(props)
        }
        case ELEMENTS: return getPriceItemButtons(props)
        default: return []
      }
    }
    case types.PRICEITEM: {
      return getPriceItemButtons(props)
    }
    case types.SPACES_ASSEMBLY: {
      return getAssemblyButtons(props)
    }
    case types.CREATE_OTHER_SPACES: {
      return getCreateOtherSpacesWidgetButtons(props)
    }
    case types.CREATE_CIRCULATION: {
      return getCreateCirculationButtons(props)
    }
    case types.CREATE_FACILITY_SERVICES: {
      return getCreateFacilityServicesButtons(props)
    }
    case types.CREATE_TECHNICAL_BUILDING_SERVICES: {
      return getCreateTechnicalBuildingServicesButtons(props)
    }
    case types.CREATE_PROTECTED_CIRCULATION: {
      return getCreateProtectedCirculationButtons(props)
    }
    case types.MULTI_EDIT_SPACE_WIDGET:
    case types.MULTI_EDIT_SPACE_GROUP_WIDGET: {
      return getMultiEditWidgetButtons(props)
    }
    default:
      return []
  }
}
