// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

const worker = window.Worker ? new Worker('/mfe-web-worker/procurement_elements') : null

if (!worker) {
  console.error('Could not get Worker from window')
}

export default worker
